import React, { useEffect, useMemo, useState } from 'react';
import {
    CrudDialog,
    CrudInputType,
    type CrudSchema,
    type CrudSelectInputOptions,
    CrudTable
} from '@/components/ui/crud-table';
import { type FormListEntry } from '@/types/api/forms';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import { type ColumnDef, type PaginationState } from '@tanstack/react-table';
import { getFormStatusFromList, getFormUrl } from '@/composables/neoform';
import { NeoFormStatus } from '@/types/neoform';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { faCheck, faFolderOpen, faLock, faUsers } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';
import { Linear } from '@/components/ui/linear';
import { getForms, getFormsList, postCreateForm } from '@/composables/api';
import { useLanguage } from '@/composables/language';
import { useQuery } from '@/composables/query';

export function Forms() {
    const { t, to, ct } = useTranslation('forms');
    const { options } = useLanguage();
    const { case_id, folder_id } = useQuery<{ case_id: string; folder_id: string }>();

    const [forms, setForms] = useState<any[]>([]);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10
    });

    const groups = useMemo(
        () => forms.reduce(
            (obj, form) => {
                if (form.category) {
                    obj[form.category.name] = form.category.label;
                }
                return obj;
            }, {}
        ) ?? {},
        [forms]
    );

    const EditableCell: ColumnDef<FormListEntry>['cell'] = ({ cell }) => {
        const value = cell.getValue<string>();
        return (
            <Button className="!tw-p-0" variant="link" asChild>
                <Link to={`/folder/${value}`}>{value}</Link>
            </Button>
        );
    };

    const StatusCell: ColumnDef<FormListEntry>['cell'] = ({ cell }) => {
        const value = cell.getValue<FormListEntry['status']>();
        const [status, progress] = getFormStatusFromList(value);
        const isCompleted = status === NeoFormStatus.COMPLETED;
        const isClosed = status === NeoFormStatus.CLOSED;
        return (
            <div className="tw-flex tw-items-center tw-justify-center">
                <Badge
                    className="!tw-rounded-full !tw-font-normal" size="md"
                    variant={isClosed ? 'warning' : isCompleted ? 'success' : 'default'}
                >
                    {(isClosed || isCompleted) &&
                        <FontAwesomeIcon
                            className="tw-mr-2"
                            icon={isCompleted ? faCheck : faLock}
                        />
                    }
                    {!isCompleted && !isClosed &&
                        <span className="tw-mr-2 tw-whitespace-nowrap">
                            {progress} %
                        </span>
                    }
                    {!isCompleted && !isClosed
                        ? <Linear
                            className="tw-text-white tw-min-w-[70px]"
                            variant="determinate"
                            value={progress}
                        />
                        : isCompleted ? ct('completed') : ct('closed')
                    }
                </Badge>
            </div>
        );
    };

    const ActionCell: ColumnDef<FormListEntry>['cell'] = ({ row }) => {
        const url = getFormUrl(row.original.form_name, row.original.id);
        return (
            <div className="tw-flex tw-gap-1">
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button
                            className="tw-text-muted-foreground"
                            variant="ghost" size="icon"
                            asChild
                        >
                            <a href={url}>
                                <FontAwesomeIcon icon={faFolderOpen}/>
                            </a>
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                        {t('table.open')}
                    </TooltipContent>
                </Tooltip>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button
                            className="tw-text-muted-foreground"
                            variant="ghost" size="icon"
                            asChild
                        >
                            <a href={`${url}/send`}>
                                <FontAwesomeIcon icon={faUsers} />
                            </a>
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                        {t('table.send')}
                    </TooltipContent>
                </Tooltip>
            </div>
        );
    };

    const DateCell: ColumnDef<FormListEntry>['cell'] = ({ cell }) => {
        const value = cell.getValue<string>();
        const date = DateTime.fromISO(value);
        return (
            <>{date.isValid && date.toFormat('ff')}</>
        );
    };

    function fetchForms() {
        return getForms()
            .then((res) => {
                setForms(res.data
                    .map((f: any) => ({
                        id: f.id,
                        label: f.title,
                        form_name: f.form_name,
                        form_type: f.form_type,
                        category: f.category
                    })).sort((a: any, b: any) =>
                        (a.category?.name ?? 'default')
                            .localeCompare(b.category?.name ?? 'default'))
                );
            });
    }

    useEffect(() => {
        fetchForms().then();
    }, []);

    const schema = useMemo<CrudSchema<FormListEntry>>(() => [
        {
            id: 'ticket_id',
            type: CrudInputType.TEXT,
            name: t('table.folder'),
            required: true,
            col: 6,
            columnDef: {
                id: 'ticket_id',
                accessorKey: 'ticket_id',
                header: t('table.folder'),
                cell: EditableCell
            }
        },
        {
            id: 'title',
            type: CrudInputType.TEXT,
            translate: true,
            create: false,
            columnDef: {
                id: 'title',
                accessorKey: 'title',
                header: t('table.title'),
                cell: ({ cell }) => <Tooltip>
                    <TooltipTrigger asChild>
                        <p className="tw-max-w-[200px] tw-truncate">
                            {to(cell.getValue<TranslationObject>())}
                        </p>
                    </TooltipTrigger>
                    <TooltipContent align="start">
                        {to(cell.getValue<TranslationObject>())}
                    </TooltipContent>
                </Tooltip>,
                sortingFn: 'auto'
            }
        },
        {
            id: 'lang',
            type: CrudInputType.SELECT,
            name: ct('language'),
            required: true,
            col: 6,
            options,
            getOptionValue: (opt) => opt.value,
            getOptionLabel: (opt) => opt.label
        } as CrudSelectInputOptions<FormListEntry, typeof options[number]>,
        {
            id: 'form_id',
            type: CrudInputType.SELECT,
            name: ct('form'),
            required: true,
            options: forms,
            getOptionValue: (opt) => opt.id,
            getOptionLabel: (opt) => to(opt.label),
            groupBy: (opt) => to(groups[opt.category?.name] ?? 'default')
        } as CrudSelectInputOptions<FormListEntry, any>,
        {
            id: 'date_created',
            type: CrudInputType.TEXT,
            name: t('table.date'),
            create: false,
            columnDef: {
                id: 'date_created',
                accessorKey: 'date_created',
                header: t('table.date'),
                filterFn: (row, columnId, filterValue: DateTime) => {
                    const value = row.getValue<string>(columnId);
                    const date = DateTime.fromISO(value).startOf('day');
                    return !filterValue?.isValid || filterValue.startOf('day').equals(date);
                },
                cell: DateCell
            }
        },
        {
            id: 'status',
            type: CrudInputType.TEXT,
            name: t('table.status'),
            create: false,
            columnDef: {
                id: 'status',
                accessorKey: 'status',
                header: t('table.status'),
                cell: StatusCell,
                size: 50,
                filterFn: (row, id, filterValue) => {
                    const value = row.getValue<FormListEntry['status']>(id);
                    const [status] = getFormStatusFromList(value);
                    return !filterValue || status === filterValue;
                },
                sortingFn: (rowA, rowB, columnId) => {
                    const [statusA, progressA] = getFormStatusFromList(rowA.getValue(columnId));
                    const [statusB, progressB] = getFormStatusFromList(rowB.getValue(columnId));
                    const statusDiff = statusA - statusB;
                    if (statusDiff === 0) {
                        return (progressA ?? 0) - (progressB ?? 0);
                    }
                    return statusDiff;
                }
            }
        }
    ], [forms]);

    const DialogComponent = useMemo(
        () => CrudDialog({ schema, idKey: 'id' }),
        [schema]
    );

    function serverSearchFn(search: string) {
        return getFormsList({ ticket_id: search });
    }

    return (
        <main className="tw-min-h-full tw-p-4">
            <Card>
                <CardHeader>
                    <CardTitle>{t('title')}</CardTitle>
                </CardHeader>
                <CardContent>
                    <CrudTable<FormListEntry, 'id'>
                        serverSearch={serverSearchFn}
                        idKey="id"
                        schema={schema}
                        actions={ActionCell}
                        onCreate={(value) => postCreateForm({
                            form_id: value.form_id ?? '',
                            lang: value.lang ?? '',
                            ticket_id: value.ticket_id ?? ''
                        }).then()}
                        onRead={() =>
                            getFormsList(
                                { case_id, ticket_id: folder_id },
                                pagination.pageSize,
                                pagination.pageIndex + 1
                            ).then((res) => {
                                const data = res.data;
                                setRowCount(data.nb_rows);
                                setPagination({
                                    pageSize: pagination.pageSize,
                                    pageIndex: data.page ?? 0
                                });
                                return data.data.map(d => ({
                                    ...d,
                                    date_created: DateTime
                                        .fromFormat(d.date_created, 'yyyy-MM-dd HH:mm:ss')
                                        .toISO()
                                }));
                            })
                        }
                        dialogComponent={DialogComponent}
                        state={{ pagination }}
                        rowCount={rowCount}
                        onPaginationChange={setPagination}
                        manualPagination
                    />
                </CardContent>
            </Card>
        </main>
    );
}
