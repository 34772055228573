import React, { useState } from 'react';
import { Button, type ButtonProps } from '@/components/ui/button';

interface Props extends ButtonProps {
    onClick?: () => Promise<void> | void;
}

export function ButtonAsync(props: Props) {
    const [loading, setLoading] = useState(false);
    async function handleClick() {
        setLoading(true);
        try {
            await props.onClick?.();
        } catch (err) {
        } finally {
            setLoading(false);
        }
    }
    return (
        <Button
            {...props}
            loading={!!props.loading || loading}
            onClick={handleClick}
        >
            {props.children}
        </Button>
    );
}
