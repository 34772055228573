import React, { useState } from 'react';
import { type Language, type TranslationObject, useTranslation } from '@/composables/translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNeoForm } from '@/composables/neoform';
import { getGeneratedField } from '@/composables/api';
import { toast } from 'react-toastify';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { InfoButton } from '@/components/InfoButton';
import { cn } from '@/lib/utils';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';

export enum NeoFormTitleType {
    DEFAULT = 'default',
    HEADER = 'header',
    LABEL = 'label'
}

const TITLE_ELEMENTS = {
    [NeoFormTitleType.DEFAULT]: 'h5',
    [NeoFormTitleType.HEADER]: 'h5',
    [NeoFormTitleType.LABEL]: Label
};

interface Props {
    lang: Language;
    // TODO: Remove this prop
    visible: boolean;
    name: string;
    titleType?: NeoFormTitleType;
    title?: TranslationObject;
    description?: TranslationObject;
    info?: TranslationObject;
    generate?: {
        endpoint: string;
        prompt: string;
    };
    for?: string;
}

export function NeoFormComponentPreface(props: Props) {
    const { t, to } = useTranslation('neoform');
    const { form } = useNeoForm();
    const [isGenerateLoading, setIsGenerateLoading] = useState(false);
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    const titleType = props.titleType || NeoFormTitleType.DEFAULT;
    const title = to(props.title, '', props.lang);
    const description = to(props.description, '', props.lang);
    const info = to(props.info, '', props.lang);
    const show = !!title || !!description;
    if (!props.visible || !show) {
        return null;
    }

    function handleGenerate() {
        if (!form?.info || !props.generate) {
            return;
        }
        setIsGenerateLoading(true);
        return form.saveForm()
            .then(() => getGeneratedField(props.generate?.endpoint ?? '', form.info?.task_id ?? ''))
            .then((res) => form?.set(props.name, res.data.med_generic))
            .catch(() => toast(t('messages.error'), { type: 'error' }))
            .finally(() => setIsGenerateLoading(false));
    }

    return (
        <div className={cn('tw-mb-1', `neo-title-${titleType}`)}>
            {title &&
                <div className={cn('tw-flex tw-items-center')}>
                    {React.createElement(TITLE_ELEMENTS[titleType], { htmlFor: props.for }, title)}
                    {info && <InfoButton className="tw-ml-1" text={info} />}
                    {props.generate &&
                        <Button
                            type="button"
                            className="tw-ml-auto"
                            disabled={isGenerateLoading}
                            onClick={handleGenerate}
                        >
                            {isGenerateLoading
                                ? <Spinner
                                    className="tw-mr-2 tw-text-white"
                                />
                                : <FontAwesomeIcon
                                    className="tw-mr-2"
                                    size="lg"
                                    icon={faWandMagicSparkles}
                                />
                            }
                            {t('generate-ai')}
                        </Button>
                    }
                </div>
            }
            {description &&
                <div
                    className="tw-mb-2 tw-text-sm tw-max-w-none tw-prose"
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            }
        </div>
    );
}
