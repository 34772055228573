import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { Button } from './button';
import { CalendarIcon } from '@radix-ui/react-icons';
import { Calendar } from './calendar';
import { Input, type InputProps } from '@/components/ui/input';
import { IMask, useIMask } from 'react-imask';
import { assignRef, combineDateTime } from '@/composables/utils';
import { useControllableState } from '@/composables/controllable';
import { cn } from '@/lib/utils';
import { type DayPickerSingleProps } from 'react-day-picker';

interface Props extends Omit<InputProps, 'onChange' | 'value'> {
    value?: DateTime | null;
    onChange?: React.Dispatch<DateTime | null>;
    calendarProps?: Partial<DayPickerSingleProps>;
}

const DATE_FORMAT = 'yyyy-MM-dd';

const DatePicker = React.forwardRef<HTMLInputElement, Props>(({ calendarProps, ...props }, inputRef) => {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useControllableState(
        null, props.value,
        props.onChange as React.Dispatch<React.SetStateAction<DateTime | null>>
    );
    const mask = useIMask(
        {
            lazy: false,
            overwrite: true,
            autofix: false,
            mask: 'YYYY{-}MM{-}DD',
            blocks: {
                YYYY: {
                    mask: IMask.MaskedRange,
                    placeholderChar: 'y',
                    from: 1900,
                    to: 2500,
                    maxLength: 4
                },
                MM: {
                    mask: IMask.MaskedRange,
                    placeholderChar: 'm',
                    from: 1,
                    to: 12,
                    maxLength: 2
                },
                DD: {
                    mask: IMask.MaskedRange,
                    placeholderChar: 'd',
                    from: 1,
                    to: 31,
                    maxLength: 2
                }
            }
        },
        {
            onAccept: (newValue) => {
                const date = DateTime.fromFormat(newValue, DATE_FORMAT);
                let dateValue = date.isValid ? date : null;
                if (dateValue && value?.isValid === true) {
                    dateValue = combineDateTime(date, value);
                }
                setValue(dateValue);
                if (dateValue) {
                    mask.setValue(newValue);
                }
            }
        }
    );
    const [month, setMonth] = useState<Date>(value?.toJSDate() ?? new Date());
    useEffect(() => {
        if (value) {
            mask.setValue(value.toFormat(DATE_FORMAT));
            setMonth(value.toJSDate());
        } else {
            mask.setUnmaskedValue('');
        }
    }, [value]);
    return (
        <div className={cn('tw-relative', props.className)}>
            <Popover modal open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                    <Button
                        className={cn(
                            'tw-absolute tw-left-[1px] tw-inset-y-[1px] !tw-h-[34px]',
                            '!tw-pl-3 !tw-pr-2.5 tw-rounded-r-none tw-border-r tw-z-[1]'
                        )}
                        type="button"
                        variant="ghost"
                        disabled={props.disabled}
                    >
                        <CalendarIcon />
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="tw-w-auto !tw-p-0" align="start">
                    <Calendar
                        mode="single"
                        selected={props.value?.toJSDate()}
                        month={month}
                        onMonthChange={setMonth}
                        onSelect={(value) => {
                            const newValue = value && props.value
                                ? combineDateTime(DateTime.fromJSDate(value), props.value)
                                : value
                                    ? DateTime.fromJSDate(value)
                                    : null;
                            setValue(newValue);
                            setOpen(false);
                        }}
                        initialFocus
                        {...calendarProps}
                    />
                </PopoverContent>
            </Popover>
            <Input
                {...props}
                className="tw-pl-12"
                ref={(ref) => {
                    mask.ref.current = ref;
                    assignRef(inputRef, ref);
                }}
                value={mask.value}
                onChange={(e) => mask.setUnmaskedValue(e.target.value)}
            />
        </div>
    );
});
DatePicker.displayName = 'DatePicker';

export { DatePicker };
