import _ from 'lodash';
import { type UserGroup } from '@/types/api/user';
import { useTranslation } from '@/composables/translation';
import { useMemo } from 'react';

interface Options {
    groups: UserGroup[];
}

export function useGroups(opts: Options) {
    const { ct, to } = useTranslation();
    const [domains, groups] = useMemo(
        () => _.partition(opts.groups, (g) => g.domain),
        [opts.groups]
    );
    const specialties = useMemo(
        () => opts.groups.filter(g => g.category === 'speciality'),
        [opts.groups]
    );
    return {
        groups,
        specialties,
        domains,
        groupBy: (group: UserGroup) =>
            group.domain
                ? to({ fr: 'Domaines', en: 'Domains' })
                : group.parent?.$oid
                    ? to(domains.find(d => d._id.$oid === group.parent?.$oid)?.name, ct('others'))
                    : ct('others')
    };
}
