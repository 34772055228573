import { combine } from '@/composables/translation';

export const RELATIONSHIPS = [
    { value: 'none', label: combine('neoform.relationship-options.none') },
    { value: 'child', label: combine('neoform.relationship-options.child') },
    { value: 'spouse', label: combine('neoform.relationship-options.spouse') },
    { value: 'grandchild', label: combine('neoform.relationship-options.grandchild') },
    { value: 'sibling', label: combine('neoform.relationship-options.sibling') },
    { value: 'parent', label: combine('neoform.relationship-options.parent') },
    { value: 'nephew', label: combine('neoform.relationship-options.nephew') },
    { value: 'grandnephew', label: combine('neoform.relationship-options.grandnephew') },
    { value: 'uncle', label: combine('neoform.relationship-options.uncle') },
    { value: 'cousin', label: combine('neoform.relationship-options.cousin') },
    { value: 'first-cousin', label: combine('neoform.relationship-options.first-cousin') },
    { value: 'second-cousin', label: combine('neoform.relationship-options.second-cousin') },
    { value: 'grandparent', label: combine('neoform.relationship-options.grandparent') },
    { value: 'sibling-in-law', label: combine('neoform.relationship-options.sibling-in-law') },
    { value: 'parent-in-law', label: combine('neoform.relationship-options.parent-in-law') },
    { value: 'stepchild', label: combine('neoform.relationship-options.stepchild') },
    { value: 'other', label: combine('neoform.relationship-options.other') }
];

export const YES_NO = [
    { value: 'yes', label: combine('common.yes') },
    { value: 'no', label: combine('common.no') }
];

export const CONTROLS = [
    { value: 'direct', label: combine('neoform.stakeholder.control.item-0') },
    { value: 'indirect', label: combine('neoform.stakeholder.control.item-1') },
    { value: 'defait', label: combine('neoform.stakeholder.control.item-2') }
];

export const LEGAL_FORMS = [
    { value: 'individuelle', label: combine('neoform.legal-forms.individual') },
    { value: 'action', label: combine('neoform.legal-forms.joint-stock') },
    { value: 'collectif', label: combine('neoform.legal-forms.general-partnership') },
    { value: 'commandite', label: combine('neoform.legal-forms.general-partnership') },
    { value: 'participation', label: combine('neoform.legal-forms.venture') },
    { value: 'cooperative', label: combine('neoform.legal-forms.cooperative') },
    { value: 'fiducie-exploitant-no-unit', label: combine('neoform.legal-forms.fiducie-no-unit') },
    { value: 'fiducie-exploitant-unit', label: combine('neoform.legal-forms.fiducie-unit') },
    { value: 'autre', label: combine('neoform.legal-forms.other') }
];

export const LEGACY_TYPES = [
    { value: '1', label: combine('neoform.good.legacy-type-0') },
    { value: '16', label: combine('neoform.good.legacy-type-1') },
    { value: '4', label: combine('neoform.good.legacy-type-2') },
    { value: '18', label: combine('neoform.good.legacy-type-3') },
    { value: '12', label: combine('neoform.good.legacy-type-4') },
    { value: '13', label: combine('neoform.good.legacy-type-5') },
    { value: '14', label: combine('neoform.good.legacy-type-6') },
    { value: '19', label: combine('neoform.good.legacy-type-7') },
    { value: '20', label: combine('neoform.good.legacy-type-8') },
    { value: '21', label: combine('neoform.good.legacy-type-9') },
    { value: '22', label: combine('neoform.good.legacy-type-10') },
    { value: '23', label: combine('neoform.good.legacy-type-11') },
    { value: '999', label: combine('neoform.good.legacy-type-13') }
];

export const LEGACY_TYPES_CA = [
    { value: '1', label: combine('neoform.good.legacy-type-0') },
    { value: '16', label: combine('neoform.good.legacy-type-1') },
    { value: '4', label: combine('neoform.good.legacy-type-2') },
    { value: '18', label: combine('neoform.good.legacy-type-3') },
    { value: '19', label: combine('neoform.good.legacy-type-7') },
    { value: '20', label: combine('neoform.good.legacy-type-8') },
    { value: '21', label: combine('neoform.good.legacy-type-9') },
    { value: '22', label: combine('neoform.good.legacy-type-10') },
    { value: '23', label: combine('neoform.good.legacy-type-11') },
    { value: '999', label: combine('neoform.good.legacy-type-13') }
];

export const LEGACY_ACTION_TYPES = [
    { value: '0', label: combine('neoform.good.action-type-0') },
    { value: '1', label: combine('neoform.good.action-type-1') }
];

export const SHARE_CATEGORIES = [
    { value: 'a', label: { en: 'A', fr: 'A' } },
    { value: 'b', label: { en: 'B', fr: 'B' } },
    { value: 'c', label: { en: 'C', fr: 'C' } },
    { value: 'd', label: { en: 'D', fr: 'D' } },
    { value: 'e', label: { en: 'E', fr: 'E' } }
];

export const LEGACY_MAINTENANCE_RESPONSIBILITY = [
    { value: 'beneficiary', label: combine('neoform.good.responsible-0') },
    { value: 'succession', label: combine('neoform.good.responsible-1') }
];

export const LEGACY_DEBT_RESPONSIBILITY = [
    { value: 'legatee', label: combine('neoform.good.responsible-0') },
    { value: 'heir', label: combine('neoform.good.responsible-1') }
];

export const ADMIN_POSITIONS = [
    { value: 'president', label: combine('neoform.position.item-0') },
    { value: 'vice-president', label: combine('neoform.position.item-1') },
    { value: 'secretary', label: combine('neoform.position.item-2') },
    { value: 'treasurer', label: combine('neoform.position.item-3') },
    { value: 'ceo', label: combine('neoform.position.item-4') },
    { value: 'cfo', label: combine('neoform.position.item-5') },
    { value: 'other', label: combine('neoform.position.item-6') }
];
