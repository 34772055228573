import { useUserStore } from '@/store/user';
import { type TranslationObject } from '@/composables/translation';
import BitSet from 'bitset';
import { type LoaderFunction } from '@remix-run/router/utils';
import { redirectWithParams } from '@/composables/utils';

export enum Permission {
    CALENDAR_CONFIG_SELECT = 0,
    CALENDAR_CONFIG_UPDATE = 1,
    CALENDAR_CONFIG_GET_PUBLIC_LINK = 2,

    CALENDAR_DISPONIBILITIES_SELECT = 3,
    CALENDAR_DISPONIBILITIES_UPDATE = 4,

    CALENDAR_AVAILABILITIES_SELECT = 5,

    CALENDAR_APPOINTMENT_SELECT = 6,
    CALENDAR_APPOINTMENT_UPDATE = 7,
    CALENDAR_APPOINTMENT_DELETE = 8,
    CALENDAR_APPOINTMENT_SCHEDULE = 9,
    CALENDAR_APPOINTMENT_OWN_HOLIDAY = 10,
    CALENDAR_APPOINTMENT_HOLIDAY = 11,

    PSJ_ADMIN_PANEL = 12
}

export const PERMISSION_VALUES =
    Object.values(Permission)
        .filter(v => typeof v === 'number') as Permission[];

export const PERMISSION_KEYS =
    Object.keys(Permission)
        .filter(k => isNaN(Number(k)));

export interface PermissionObject {
    id: string;
    name: string | TranslationObject;
    value: Permission;
}

export interface PermissionGroup {
    id: string;
    name: string | TranslationObject;
    children: Array<PermissionObject | PermissionGroup>;
}

export const PERMISSION_LIST: PermissionGroup[] = [
    {
        id: 'admin',
        name: {
            en: 'Administration',
            fr: 'Administration'
        },
        children: [
            {
                id: String(Permission.PSJ_ADMIN_PANEL),
                name: {
                    en: 'See control panel',
                    fr: 'Voir le panneau de configuration'
                },
                value: Permission.PSJ_ADMIN_PANEL
            }
        ]
    },
    {
        id: 'calendar',
        name: {
            en: 'Calendar',
            fr: 'Calendrier'
        },
        children: [
            {
                id: 'config',
                name: {
                    en: 'Config',
                    fr: 'Configuration'
                },
                children: [
                    {
                        id: String(Permission.CALENDAR_CONFIG_SELECT),
                        name: {
                            en: 'See calender config',
                            fr: 'Voir les configurations du calendrier'
                        },
                        value: Permission.CALENDAR_CONFIG_SELECT
                    },
                    {
                        id: String(Permission.CALENDAR_CONFIG_UPDATE),
                        name: {
                            en: 'Edit calender config',
                            fr: 'Modifier les configurations du calendrier'
                        },
                        value: Permission.CALENDAR_CONFIG_UPDATE
                    },
                    {
                        id: String(Permission.CALENDAR_CONFIG_GET_PUBLIC_LINK),
                        name: {
                            en: 'Generate public appointment links',
                            fr: 'Générer un lien publique pour prendre un rendez-vous'
                        },
                        value: Permission.CALENDAR_CONFIG_GET_PUBLIC_LINK
                    }
                ]
            },
            {
                id: 'dispos',
                name: {
                    en: 'Dispos',
                    fr: 'Dispos'
                },
                children: [
                    {
                        id: String(Permission.CALENDAR_DISPONIBILITIES_SELECT),
                        name: {
                            en: 'See availabilities in the control panel',
                            fr: 'Afficher les disponibilités dans le panneau de configuration'
                        },
                        value: Permission.CALENDAR_DISPONIBILITIES_SELECT
                    },
                    {
                        id: String(Permission.CALENDAR_DISPONIBILITIES_UPDATE),
                        name: {
                            en: 'Add/Edit availabilities in the control panel',
                            fr: 'Ajouter/Modifier les disponibilités dans le panneau de configuration'
                        },
                        value: Permission.CALENDAR_DISPONIBILITIES_UPDATE
                    }
                ]
            },
            {
                id: 'availabilities',
                name: {
                    en: 'Availabilities',
                    fr: 'Availabilities'
                },
                children: [
                    {
                        id: String(Permission.CALENDAR_AVAILABILITIES_SELECT),
                        name: {
                            en: 'See availabilities by appointment',
                            fr: 'Voir les disponibilités sur un rendez-vous'
                        },
                        value: Permission.CALENDAR_AVAILABILITIES_SELECT
                    }
                ]
            },
            {
                id: 'appointments',
                name: {
                    en: 'Appointments',
                    fr: 'Appointments'
                },
                children: [
                    {
                        id: String(Permission.CALENDAR_APPOINTMENT_SELECT),
                        name: {
                            en: 'Book appointments',
                            fr: 'Ajouter des rendez-vous'
                        },
                        value: Permission.CALENDAR_APPOINTMENT_SELECT
                    },
                    {
                        id: String(Permission.CALENDAR_APPOINTMENT_UPDATE),
                        name: {
                            en: 'Update appointments',
                            fr: 'Mettre à jour des rendez-vous'
                        },
                        value: Permission.CALENDAR_APPOINTMENT_UPDATE
                    },
                    {
                        id: String(Permission.CALENDAR_APPOINTMENT_DELETE),
                        name: {
                            en: 'Delete appointments',
                            fr: 'Supprimer des rendez-vous'
                        },
                        value: Permission.CALENDAR_APPOINTMENT_DELETE
                    },
                    {
                        id: String(Permission.CALENDAR_APPOINTMENT_SCHEDULE),
                        name: {
                            en: 'See schedule',
                            fr: 'Voir les rendez-vous'
                        },
                        value: Permission.CALENDAR_APPOINTMENT_SCHEDULE
                    },
                    {
                        id: String(Permission.CALENDAR_APPOINTMENT_OWN_HOLIDAY),
                        name: {
                            en: 'Add own holiday',
                            fr: 'Ajouter un congé a soit-même'
                        },
                        value: Permission.CALENDAR_APPOINTMENT_OWN_HOLIDAY
                    },
                    {
                        id: String(Permission.CALENDAR_APPOINTMENT_HOLIDAY),
                        name: {
                            en: 'Add holidays for other users',
                            fr: 'Ajouter des congés pour les autres utilisateurs'
                        },
                        value: Permission.CALENDAR_APPOINTMENT_HOLIDAY
                    }
                ]
            }
        ]
    }
];

export function usePermissions() {
    const userPermissions = useUserStore(
        state => {
            return BitSet.fromHexString(state.user?.permissions ?? '0');
        }
    );
    return {
        hasPermissions:
            (...permissions: Permission[]) => {
                const required = new BitSet(permissions);
                return (userPermissions.and(required)).equals(required);
            }
    };
}

export function RequiredPermissionLoader(...permissions: Permission[]): LoaderFunction {
    return ({ request }) => {
        const userPermissions = BitSet.fromHexString(useUserStore.getState().user?.permissions ?? '0');
        const required = new BitSet(permissions);
        if (!(userPermissions.and(required)).equals(required)) {
            return redirectWithParams('/', new URL(request.url));
        }
        return null;
    };
}
