import React, { useMemo } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { CrudInputType, type CrudSchema, CrudTable } from '@/components/ui/crud-table';
import { type ExternalCase } from '@/types/folder';
import { ExternalLinkIcon } from '@radix-ui/react-icons';
import { getExternalFolders } from '@/composables/api';
import { CaseStatusCell, DateCell, LinkCell } from '@/components/ui/cells';
import type { Party } from '@/types/api/party';
import { DateTime } from 'luxon';
import { useTranslation } from '@/composables/translation';

export function ExternalFolders() {
    const { t } = useTranslation('dashboard');

    const schema = useMemo<CrudSchema<ExternalCase>>(
        () => [
            {
                id: 'folder_id',
                type: CrudInputType.TEXT,
                name: t('table.id'),
                col: 6,
                create: true,
                columnDef: {
                    id: 'folder_id',
                    header: t('table.id'),
                    accessorKey: 'folder_id',
                    cell: LinkCell<any>({
                        link: ({ row }) => row.original.url,
                        content: ({ cell }) => (<>
                            <ExternalLinkIcon className="tw-mr-1" />
                            {cell.getValue<string>()}
                        </>),
                        target: '_blank'
                    })
                }
            },
            {
                id: 'name',
                type: CrudInputType.TEXT,
                name: t('table.name'),
                col: 6,
                columnDef: {
                    id: 'name',
                    header: t('table.name'),
                    accessorKey: 'name'
                }
            },
            {
                id: 'status',
                type: CrudInputType.TEXT,
                name: t('table.status'),
                columnDef: {
                    id: 'status',
                    header: t('table.status'),
                    accessorKey: 'status',
                    cell: CaseStatusCell
                }
            },
            {
                id: 'parties',
                type: CrudInputType.TEXT,
                name: t('table.client'),
                columnDef: {
                    id: 'parties',
                    header: t('table.client'),
                    accessorFn: (row) => row.parties.find(p => p.type === 'client'),
                    cell: ({ cell }) => cell.getValue<Party | undefined>()?.fullname
                }
            },
            {
                id: 'updated_at',
                type: CrudInputType.TEXT,
                name: t('table.updated_at'),
                columnDef: {
                    id: 'updated_at',
                    header: t('table.updated_at'),
                    accessorKey: 'updated_at',
                    cell: DateCell
                }
            },
            {
                id: 'created_at',
                type: CrudInputType.TEXT,
                name: t('table.created_at'),
                columnDef: {
                    id: 'created_at',
                    header: t('table.created_at'),
                    accessorKey: 'created_at',
                    cell: DateCell
                }
            },
            {
                id: 'created_at_relative',
                type: CrudInputType.TEXT,
                update: false,
                create: false,
                columnDef: {
                    id: 'created_at_relative',
                    accessorKey: 'created_at',
                    header: t('table.created_at'),
                    cell: ({ cell }) => {
                        const date = DateTime.fromISO(cell.getValue<string>());
                        return date.isValid ? date.toRelative() : null;
                    }
                }
            }
        ],
        []
    );
    return (
        <main className="tw-min-h-full !tw-p-4">
            <Card>
                <CardHeader>
                    <CardTitle>
                        {t('external')}
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <CrudTable<ExternalCase, 'folder_id'>
                        idKey="folder_id"
                        schema={schema}
                        onRead={() => getExternalFolders().then(res => res.data.data)}
                    />
                </CardContent>
            </Card>
        </main>
    );
}
