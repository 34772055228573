import React, { useRef, type ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';

interface DropZoneWrapperProps {
    children: ReactNode;
    handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
    hoverBorder?: boolean;
}

const DropZoneWrapper: React.FC<DropZoneWrapperProps> = ({
    children,
    handleFileUpload,
    hoverBorder
}) => {
    const fileInput = useRef<HTMLInputElement | null>(null);

    const onDrop = (acceptedFiles: File[]) => {
        const inputEvent = {
            target: {
                files: acceptedFiles
            }
        } as unknown as React.ChangeEvent<HTMLInputElement>;
        handleFileUpload(inputEvent);
    };

    const { getRootProps, getInputProps, isDragActive, isFocused } = useDropzone({
        onDrop,
        noClick: true
    });

    return (
        <div
            {...getRootProps()}
            className={`${
                hoverBorder &&
                'hover:!tw-border-gray-500 hover:!tw-border-dashed hover:!tw-border-2'
            }`}
            style={{
                border: isDragActive
                    ? '2px dashed green'
                    : isFocused && hoverBorder
                        ? '2px dashed grey'
                        : '2px solid transparent',
                borderRadius: '10px'
            }}
        >
            <input {...getInputProps()} ref={fileInput}/>
            {children}
        </div>
    );
};

export default DropZoneWrapper;
