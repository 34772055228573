import React, { useContext } from 'react';
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useTranslation } from '@/composables/translation';
import { Button } from '@/components/ui/button';
import { Form, FormLabel, FormItem, FormControl, FormMessage, FormField } from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { useValidation } from '@/composables/validation';
import { Combobox } from '@/components/ui/combobox';
import { Textarea } from '@/components/ui/textarea';
import { Input } from '@/components/ui/input';
import { FolderContext } from '@/pages/psj/Folder';

export interface DocumentFile {
    _id?: string;
    title: string;
}

export interface DocumentRequestData {
    files: DocumentFile[];
    emails: string[];
    subject?: string;
    message?: string;
}

interface Props {
    open?: boolean;
    children?: React.ReactNode;
    onOpenChange?: React.Dispatch<boolean>;
    onSubmit?: (data: DocumentRequestData) => Promise<void> | void;
}

export function DocumentRequest(props: Props) {
    const { ct, t } = useTranslation('psj.documents.request-dialog');
    const form = useForm<DocumentRequestData>({
        defaultValues: {
            files: [],
            emails: [],
            subject: '',
            message: ''
        }
    });
    const { required } = useValidation();
    const folder = useContext(FolderContext);
    function onSubmit(data: DocumentRequestData) {
        return props.onSubmit?.(data);
    }
    return (
        <Dialog open={props.open} onOpenChange={props.onOpenChange}>
            {props.children}
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>{t('title')}</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <div className="tw-flex tw-flex-col tw-gap-3 tw-pb-6">
                            <FormField
                                name="files"
                                render={({ field: { ref, ...field } }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('fields.files')}
                                            <span className="tw-text-destructive tw-ml-1">
                                                *
                                            </span>
                                        </FormLabel>
                                        <FormControl>
                                            <Combobox<DocumentFile>
                                                getCreateValue={(title) => ({ title })}
                                                getOptionLabel={(opt) => opt.title}
                                                getValueLabel={(value) => value.title}
                                                options={[]}
                                                creatable
                                                clearable
                                                multiple
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                                rules={{ validate: { required } }}
                            />
                            <FormField
                                name="emails"
                                render={({ field: { ref, ...field } }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('fields.emails')}
                                            <span className="tw-text-destructive tw-ml-1">
                                                *
                                            </span>
                                        </FormLabel>
                                        <FormControl>
                                            <Combobox
                                                options={folder?.parties?.map(p => p.email) ?? []}
                                                creatable
                                                clearable
                                                multiple
                                                {...field}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                                rules={{ validate: { required } }}
                            />
                            <FormField
                                name="subject"
                                render={({ field }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('fields.subject')}
                                        </FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                            />
                            <FormField
                                name="message"
                                render={({ field }) =>
                                    <FormItem>
                                        <FormLabel>
                                            {t('fields.message')}
                                        </FormLabel>
                                        <FormControl>
                                            <Textarea {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                }
                            />
                        </div>
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button variant="secondary">
                                    {ct('cancel')}
                                </Button>
                            </DialogClose>
                            <ButtonSubmit>
                                {ct('send')}
                            </ButtonSubmit>
                        </DialogFooter>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    );
}
