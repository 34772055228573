import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
    getEmailQueueStatus,
    getFormClients,
    getFormData,
    postFormClients,
    postFormClientSendEmails
} from '@/composables/api';
import { v4 as uuid } from 'uuid';
import { type TreeNode, TreeViewSelect } from '@/components/TreeViewSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faCopy,
    faEnvelope,
    faLink,
    faLinkSlash,
    faMinus,
    faPlus,
    faSave,
    faSearch
} from '@fortawesome/free-solid-svg-icons';
import { ActionBar } from '@/components/ActionBar';
import { Checkbox } from '@/components/ui/checkbox';
import {
    computeNeoformOutline, getFormUrl,
    OLD_FORMS,
    preProcessNeoformMeta
} from '@/composables/neoform';
import {
    type NeoFormClient,
    NeoFormEmailQueueStatus,
    type NeoFormLayout,
    type NeoFormMeta
} from '@/types/neoform';
import _ from 'lodash';
import { FillFlexParent } from '@/components/utils/FlexFillParent';
import { useTranslation } from '@/composables/translation';
import { toast } from 'react-toastify';
import { writeClipboardText } from '@/composables/clipboard';
import { useMergeState } from '@/composables/merge';
import { Row, Col } from '@/components/ui/row';
import ReactQuill from 'react-quill';
import { CardCollapse } from '@/components/CardCollapse';
import { Separator } from '@/components/ui/separator';
import { Button } from '@/components/ui/button';
import { ArrowLeftIcon } from '@radix-ui/react-icons';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { DataTable } from '@/components/ui/data-table';
import { type ColumnDef } from '@tanstack/react-table';
import { Input } from '@/components/ui/input';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { Switch } from '@/components/ui/switch';
import { Badge } from '@/components/ui/badge';
import { Spinner } from '@/components/ui/spinner';
import { Label } from '@/components/ui/label';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';

const LinkCell: ColumnDef<NeoFormClient>['cell'] = ({ cell }) => {
    const { ct } = useTranslation();
    const [copied, setCopied] = useState(false);

    const link = cell.getValue<string>();

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        writeClipboardText(link).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 5000);
        });
    };

    return (
        <div className="tw-flex tw-justify-center tw-items-center">
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button
                        className={cn(copied ? 'tw-text-success' : 'tw-text-primary')}
                        variant="outline" size="icon"
                        disabled={!link}
                        onClick={handleClick}
                    >
                        <FontAwesomeIcon icon={copied ? faCheck : faCopy}/>
                    </Button>
                </TooltipTrigger>
                <TooltipContent>
                    {copied ? ct('copied-link') : ct('copy')}
                </TooltipContent>
            </Tooltip>
        </div>
    );
};

const QUEUE_POLL_DELAY = 5000;

export function NeoFormSend() {
    const {
        t,
        to,
        ct
    } = useTranslation('neoform');

    const [loading, setLoading] = useState(false);
    const [meta, setMeta] = useState<NeoFormMeta>({});
    const [layout, setLayout] = useState<NeoFormLayout>();
    const [formOutline, setFormOutline] = useState<TreeNode[]>([]);
    const [clients, setClients] = useState<NeoFormClient[]>([]);
    const [
        queueStatus,
        setQueueStatus
    ] = useMergeState<Record<string, NeoFormEmailQueueStatus>>({});
    const queueWatchlist = useRef<Set<string>>(new Set<string>());
    const [selected, setSelected] = useState<Record<string, boolean>>({});
    const [search, setSearch] = useState('');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');

    const selectedList = Object.entries(selected).filter(([_, v]) => v).map(([k]) => k);
    const numSelected = selectedList.length;
    const [clientID] = selectedList;
    const client = numSelected === 1 ? clients.find(c => c.id === clientID) : undefined;
    const clientDataValid = clients.every(
        c =>
            !!c.firstname?.trim() &&
            !!c.lastname?.trim() &&
            !!c.email?.address?.trim()
    );
    // TODO: Add warning dialog if no permissions selected before sending email to clients
    // const isWarnUserNoPermission = selectedList.some(id => {
    //     const client = clients.find(c => c.id === id);
    //     return client &&
    //         client.form_permissions.length > 0;
    // });
    const canExpireLink = selectedList.some(id => {
        const client = clients.find(c => c.id === id);
        return !!client?.form_url?.trim();
    });
    const clientPermissions = client?.form_permissions ?? [];
    const formSections = new Set(layout?.body.map(s => s.header.sectionPrefix) ?? []);
    const isEntireFormSelected =
        formSections.size > 0 &&
        _.isEqual(formSections, new Set(clientPermissions));

    const {
        name,
        task_id,
        token
    } = useParams() as {
        name: string;
        task_id: string;
        token: string;
    };
    const isNewForm = !OLD_FORMS.has(name);

    const STATUS_STYLE = {
        [NeoFormEmailQueueStatus.SENT]: {
            variant: 'success',
            label: <>
                <FontAwesomeIcon icon={faCheck} className="tw-mr-2" />
                {t('send.email-status.sent')}
            </>
        },
        [NeoFormEmailQueueStatus.PENDING]: {
            variant: 'warning',
            label: <span className="tw-inline-flex tw-items-center">
                <Spinner className="tw-mr-2" size="sm" />
                {t('send.email-status.pending')}
            </span>
        },
        [NeoFormEmailQueueStatus.ERROR]: {
            variant: 'destructive',
            label: t('send.email-status.error')
        }
    } as const;

    const formPermissionTemplates = Object.entries(layout?.header?.config?.profile_permission ?? {})
        .map(([k, v]: [string, any]) => (
            {
                key: k,
                value: v.template,
                label: v.title,
                default: v.default
            }
        ));
    const formPermissionDefault = formPermissionTemplates.find(p => p.default);
    const formPermissionSelected = formPermissionTemplates.find(
        p => _.isEqual(new Set(p.value), new Set(clientPermissions))
    )?.key ?? 'custom';

    const handleChangePermissions = (value: string[]) => {
        if (!client) return;
        client.form_permissions = value;
        setClients([...clients]);
    };

    const handleRemoveClients = () => {
        const clientIDs = Object.entries(selected).filter(([_, v]) => v).map(([k]) => k);
        const newClients = clients.filter(c => !clientIDs.includes(c.id));
        setClients(newClients);
        setSelected({});
    };

    const handleAddClient = () => {
        setClients([
            ...clients,
            {
                id: uuid(),
                form_permissions: formPermissionDefault?.value ?? [],
                firstname: '',
                lastname: '',
                email: {
                    address: ''
                },
                init_public_url: 1,
                secure_2fa: true
            }
        ]);
    };

    const handleSaveClientList = (showFeedback = true) => {
        if (showFeedback) {
            setLoading(true);
        }
        return postFormClients(
            task_id,
            clients.map(c => (
                {
                    id: c.id,
                    firstname: c.firstname,
                    lastname: c.lastname,
                    email: c.email.address,
                    permissions: c.form_permissions,
                    secure_2fa: c.secure_2fa,
                    subject,
                    content,
                    ...(c.init_public_url != null && {
                        init_public_url: c.init_public_url
                    })
                }
            ))
        )
            .then((res) => {
                const clientList = Object.entries(res.data.clients)
                    .map(([id, client]: [string, any]) => (
                        { id, ...client }
                    ));
                setClients(clientList);
                if (showFeedback) {
                    toast(t('messages.saved'), { type: 'success' });
                }
            })
            .catch(() => toast(t('messages.error'), { type: 'error' }))
            .finally(() => {
                if (showFeedback) {
                    setLoading(false);
                }
            });
    };

    const handleSendFormEmail = () => {
        setLoading(true);
        return handleSaveClientList(false)
            .then(() => postFormClientSendEmails(task_id, [...selectedList]))
            .then((res) => {
                const status: Record<string, NeoFormEmailQueueStatus> = {};
                const clientStatus = Object.values(res.data.report)
                    .map((r, i) => {
                        const queue_id = r.queue_id ?? uuid();
                        status[queue_id] = r.status
                            ? NeoFormEmailQueueStatus.PENDING
                            : NeoFormEmailQueueStatus.ERROR;
                        if (r.status) {
                            queueWatchlist.current.add(queue_id);
                        }
                        return [i, queue_id] as const;
                    });
                setClients(clients => {
                    clientStatus.forEach(([i, queue_id]) => {
                        const client_id = selectedList[i];
                        const client = clients.find(c => c.id === client_id);
                        if (client) {
                            client.email.status?.push(queue_id);
                        }
                    });
                    return [...clients];
                });
                setQueueStatus(status);
                toast(t('messages.scheduled'), { type: 'success' });
            })
            .catch(() => toast(t('messages.error'), { type: 'error' }))
            .finally(() => setLoading(false));
    };

    const handlePollQueue = () => {
        const queue_ids = Array.from(queueWatchlist.current);
        if (queue_ids.length <= 0) return;
        getEmailQueueStatus(task_id, queue_ids)
            .then((res) => {
                const status = {};
                res.data.result?.forEach((s: any) => Object.assign(status, s));
                setQueueStatus(status);
                setClients(c => [...c]);
                Object.entries(status)
                    .forEach(([id, s]) => {
                        if (s === NeoFormEmailQueueStatus.SENT) {
                            queueWatchlist.current.delete(id);
                        }
                    });
            });
    };

    const handleGenerateFormLink = () => {
        selectedList.forEach((id) => {
            const client = clients.find(c => c.id === id);
            if (client) {
                client.init_public_url = 1;
            }
        });
        return handleSaveClientList();
    };

    const handleExpireFormLink = () => {
        selectedList.forEach((id) => {
            const client = clients.find(c => c.id === id);
            if (client) {
                client.init_public_url = 0;
            }
        });
        return handleSaveClientList();
    };

    const TextFieldCell: ColumnDef<NeoFormClient>['cell'] = ({ cell }) => {
        const client = clients.find(c => c.id === cell.row.id) as any;
        const columnKey = (cell.column.columnDef as any).accessorKey;
        const key = `${cell.row.id}-${columnKey}-input`;
        const [value, setValue] = useState(cell.getValue<string>());
        return (
            <Input
                id={key}
                key={key}
                value={value}
                onChange={(e) => {
                    const v = e.target.value;
                    _.set(client, columnKey, v);
                    setValue(v);
                }}
                onClick={(e) => e.stopPropagation()}
            />
        );
    };

    const CheckboxCell: ColumnDef<NeoFormClient>['cell'] = ({ cell }) => {
        const client = clients.find(c => c.id === cell.row.id) as any;
        const columnKey = (cell.column.columnDef as any).accessorKey;
        const key = `${cell.row.id}-${columnKey}-switch`;
        const checked = !!cell.getValue<boolean>();
        return (
            <div className="tw-flex tw-justify-center">
                <Switch
                    key={key}
                    checked={checked}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    onCheckedChange={(value) => {
                        _.set(client, columnKey, value);
                        setClients([...clients]);
                    }}
                />
            </div>
        );
    };

    const EmailStatusCell: ColumnDef<NeoFormClient>['cell'] = ({ cell }) => {
        const queue_ids = cell.getValue<string[]>();
        const queue_id = queue_ids?.[queue_ids.length - 1];
        const status = queueStatus[queue_id] || NeoFormEmailQueueStatus.NONE;
        if (status === NeoFormEmailQueueStatus.NONE) {
            return null;
        }
        const style = STATUS_STYLE[status];
        return (
            <div className="tw-flex tw-justify-center tw-items-center">
                <Badge
                    className="!tw-rounded-full tw-flex tw-items-center"
                    size="md" variant={style.variant}
                >
                    {style.label}
                </Badge>
            </div>
        );
    };

    const PermissionListCell: ColumnDef<NeoFormClient>['cell'] = ({ cell }) => {
        const { t, ct } = useTranslation('neoform.send');
        const value = cell.getValue<string[]>();
        const list = (value ?? [])
            .map((s) => {
                const parts = s.split('.');
                return parts.map(
                    (p, i) => to(_.get(meta, [...parts.slice(0, i + 1), 'meta', 'title'], p))
                );
            });
        const listStart = list.length > 3 ? list.slice(0, 2) : list;
        return (
            <div className="tw-flex tw-flex-wrap tw-gap-1 tw-p-2">
                {listStart.map((parts, i) => {
                    const label = parts.join(' > ');
                    return (
                        <Tooltip key={i}>
                            <TooltipTrigger>
                                <Badge
                                    size="sm"
                                    className="!tw-rounded-full tw-truncate tw-max-w-52"
                                >
                                    {label}
                                </Badge>
                            </TooltipTrigger>
                            <TooltipContent>
                                {label}
                            </TooltipContent>
                        </Tooltip>
                    );
                })}
                {list.length > 3 &&
                    <Badge
                        size="sm" className="!tw-rounded-full"
                    >
                        {`+${list.length - 2} ${t('others')}`}
                    </Badge>
                }
                {list.length === 0 && !value &&
                    <Badge
                        size="sm" className="!tw-rounded-full"
                    >
                        {<b>{ct('all')}</b>}
                    </Badge>
                }
            </div>
        );
    };

    const columns: Array<ColumnDef<NeoFormClient>> = [
        {
            id: 'firstname',
            accessorKey: 'firstname',
            header: ct('first-name'),
            size: 150,
            cell: TextFieldCell
        },
        {
            id: 'lastname',
            accessorKey: 'lastname',
            header: ct('last-name'),
            size: 150,
            cell: TextFieldCell
        },
        {
            id: 'email',
            accessorKey: 'email.address',
            header: ct('email'),
            size: 250,
            cell: TextFieldCell
        },
        {
            id: 'form_url',
            accessorKey: 'form_url',
            header: ct('link'),
            size: 42,
            cell: LinkCell
        },
        {
            id: 'secure_2fa',
            accessorKey: 'secure_2fa',
            header: t('send.secure'),
            size: 42,
            cell: CheckboxCell
        },
        {
            id: 'email_status',
            header: t('send.email-status.title'),
            accessorFn: (row) => row.email.status,
            size: 50,
            cell: EmailStatusCell
        },
        {
            id: 'form_permissions',
            accessorKey: 'form_permissions',
            header: ct('permissions'),
            cell: PermissionListCell
        }
    ];

    const HELP_STEPS = [
        t('send.help.step-1'),
        t('send.help.step-2'),
        t('send.help.step-3'),
        t('send.help.step-4'),
        t('send.help.step-5'),
        t('send.help.step-6'),
        t('send.help.step-7')
    ];

    useEffect(() => {
        setLoading(true);
        Promise.all([
            getFormData(name, task_id, token),
            getFormClients(task_id)
        ])
            .then(([resForm, resClients]) => {
                const result = resForm.data.result;
                const clientList = Object.entries(resClients.data.clients)
                    .map(([id, client]: [string, any]) => (
                        { id, ...client }
                    ));
                const queue_ids = clientList
                    .reduce<string[]>((arr, c) => [...arr, ...c.email.status], [])
                    .filter(q => q);
                setLayout(result.json);
                setMeta(preProcessNeoformMeta(result.json, result.data, resForm.data.meta));
                setClients(clientList);
                if (queue_ids.length > 0) {
                    return getEmailQueueStatus(task_id, queue_ids)
                        .then((res) => {
                            const status = {};
                            res.data.result?.forEach((s: any) => Object.assign(status, s));
                            setQueueStatus(status);
                            Object.entries(status)
                                .filter(([_, v]) => v === 'pending')
                                .forEach(([id]) => queueWatchlist.current?.add(id));
                        });
                }
            })
            .catch(() => toast(t('messages.error'), { type: 'error' }))
            .finally(() => setLoading(false));
        const queueInterval = setInterval(handlePollQueue, QUEUE_POLL_DELAY);
        return () => clearInterval(queueInterval);
    }, []);

    useEffect(() => {
        if (layout) {
            const outline = computeNeoformOutline(meta);
            setFormOutline(outline);
        }
    }, [meta]);

    return (
        <>
            <ActionBar className="tw-top-[60px]" loading={loading}>
                <Button className="tw-text-primary" variant="outline" asChild>
                    <Link to={getFormUrl(name, task_id)}>
                        <ArrowLeftIcon className="tw-mr-2" />
                        {t('send.back')}
                    </Link>
                </Button>
                <div className={cn(
                    'tw-ml-auto tw-flex tw-flex-wrap tw-gap-2',
                    'tw-items-center tw-justify-end'
                )}>
                    <Button
                        disabled={loading || !clientDataValid}
                        onClick={() => handleSaveClientList()}
                    >
                        <FontAwesomeIcon className="tw-mr-2" icon={faSave} />
                        {ct('save')}
                    </Button>
                    <Separator orientation="vertical" className="!tw-h-auto tw-self-stretch" />
                    <Button
                        variant="outline"
                        className="tw-text-danger"
                        disabled={loading || numSelected === 0 || !canExpireLink}
                        onClick={handleExpireFormLink}
                    >
                        <FontAwesomeIcon className="tw-mr-2" icon={faLinkSlash} />
                        {t('send.expire-link')} {numSelected !== 0
                            ? `(${numSelected})`
                            : ''}
                    </Button>
                    <Button
                        variant="outline"
                        className="tw-text-primary"
                        disabled={loading || numSelected === 0}
                        onClick={handleGenerateFormLink}
                    >
                        <FontAwesomeIcon className="tw-mr-2" icon={faLink} />
                        {t('send.generate-link')} {numSelected !== 0
                            ? `(${numSelected})`
                            : ''}
                    </Button>
                    <Separator orientation="vertical" className="!tw-h-auto tw-self-stretch" />
                    <Button
                        variant="outline"
                        className="tw-text-primary"
                        disabled={loading || numSelected === 0}
                        onClick={handleSendFormEmail}
                    >
                        <FontAwesomeIcon className="tw-mr-2" icon={faEnvelope} />
                        {t('send.send-email')} {numSelected !== 0
                            ? `(${numSelected})`
                            : ''}
                    </Button>
                </div>
            </ActionBar>
            <main className="tw-flex-1 tw-p-2 md:tw-p-4 tw-flex tw-flex-col tw-gap-2 md:tw-gap-4">
                <section>
                    <CardCollapse title={ct('help')} collapsed>
                        <CardContent className="!tw-p-6 tw-prose tw-max-w-none">
                            <p>{t('send.help.description')}</p>
                            <ol>
                                {HELP_STEPS.map((h, i) =>
                                    <li key={i}
                                        dangerouslySetInnerHTML={{ __html: h }}></li>
                                )}
                            </ol>
                            <p
                                className="tw-mb-1"
                                dangerouslySetInnerHTML={{ __html: t('send.help.footer') }}
                            />
                        </CardContent>
                    </CardCollapse>
                </section>
                <Card>
                    <CardHeader>
                        <CardTitle>
                            1. {t('send.clients')} {clients.length !== 0
                                ? `(${clients.length})`
                                : ''}
                        </CardTitle>
                    </CardHeader>
                    <CardContent>
                        <DataTable
                            state={{ rowSelection: selected }}
                            onRowSelectionChange={setSelected}
                            enableRowSelection
                            columns={columns}
                            data={clients}
                            getRowId={(row) => row.id}
                            slots={{
                                header: <div className="tw-flex tw-flex-1 tw-gap-2">
                                    <Button
                                        className="tw-text-danger"
                                        variant="outline"
                                        disabled={loading || numSelected === 0}
                                        onClick={handleRemoveClients}
                                    >
                                        <FontAwesomeIcon className="tw-mr-2" icon={faMinus} />
                                        {ct('remove')} {numSelected !== 0
                                            ? `(${numSelected})`
                                            : ''}
                                    </Button>
                                    <Button
                                        className="tw-text-success"
                                        variant="outline"
                                        disabled={loading}
                                        onClick={handleAddClient}
                                    >
                                        <FontAwesomeIcon className="tw-mr-2" icon={faPlus} />
                                        {ct('add')}
                                    </Button>
                                </div>
                            }}
                        />
                    </CardContent>
                </Card>
                <Row className={cn(
                    'tw-flex-1 tw-min-h-[500px] tw-h-1',
                    'tw-flex !tw-flex-wrap-reverse tw-gap-y-2'
                )}>
                    <Col className="tw-flex tw-flex-col" col={7}>
                        <Card className="tw-flex-1 tw-flex tw-flex-col">
                            <CardHeader>
                                <CardTitle>
                                    2. {t('send.form-outline.title')}
                                </CardTitle>
                                <CardDescription>
                                    {t('send.form-outline.description')}
                                </CardDescription>
                            </CardHeader>
                            <CardContent className="tw-flex-1 tw-flex tw-flex-col">
                                <div className="tw-flex tw-gap-3 tw-items-end tw-mt-[-1em]">
                                    <div className="tw-flex-1">
                                        <Label htmlFor="search">
                                            Recherche
                                        </Label>
                                        <Input
                                            id="search"
                                            prependIcon={<FontAwesomeIcon
                                                className="tw-text-muted-foreground"
                                                icon={faSearch}
                                            />}
                                            className="tw-flex-1"
                                            placeholder={t('send.form-outline.search-hint')}
                                            value={search}
                                            onChange={e => setSearch(e.target.value)}
                                        />
                                    </div>
                                    {formPermissionTemplates.length > 0 &&
                                        <div className="tw-flex-1">
                                            <Label htmlFor="permission-template-label">
                                                {t('send.permission-template')}
                                            </Label>
                                            <Select
                                                disabled={!isNewForm || numSelected !== 1}
                                                value={formPermissionSelected}
                                                onValueChange={(value) => {
                                                    const permission = formPermissionTemplates.find(
                                                        p => p.key === value
                                                    );
                                                    if (permission) {
                                                        handleChangePermissions(permission.value);
                                                    }
                                                }}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue id="permission-template" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        <SelectItem disabled value="custom">
                                                            {t('send.custom')}
                                                        </SelectItem>
                                                        {formPermissionTemplates.map(p =>
                                                            <SelectItem key={p.key} value={p.key}>
                                                                {to(p.label)}
                                                            </SelectItem>
                                                        )}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                        </div>
                                    }
                                </div>
                                <div className="tw-flex tw-items-center tw-gap-2 tw-my-2">
                                    <Checkbox
                                        id="select-all"
                                        checked={isEntireFormSelected ||
                                            (clientPermissions.length > 0
                                                ? 'indeterminate'
                                                : false)
                                        }
                                        disabled={!isNewForm || numSelected !== 1}
                                        onCheckedChange={(checked) => {
                                            handleChangePermissions(
                                                checked
                                                    ? [...formSections]
                                                    : []
                                            );
                                        }}
                                    />
                                    <Label htmlFor="select-all" className="!tw-text-base tw-font-medium">
                                        {ct('select.all')}
                                    </Label>
                                </div>
                                <FillFlexParent>
                                    {dimensions =>
                                        <TreeViewSelect
                                            className="tw-flex-1 tw-overflow-auto"
                                            searchTerm={search}
                                            {...dimensions}
                                            nodes={formOutline}
                                            value={clientPermissions}
                                            onChange={handleChangePermissions}
                                            disabled={!isNewForm || numSelected !== 1}
                                        />
                                    }
                                </FillFlexParent>
                            </CardContent>
                        </Card>
                    </Col>
                    <Col col={5}>
                        <Card>
                            <CardHeader>
                                <CardTitle>
                                    3. {t('send.email-content')}
                                </CardTitle>
                            </CardHeader>
                            <CardContent className="tw-flex tw-flex-col tw-gap-2">
                                <div>
                                    <Label htmlFor="subject">
                                        {`${ct('subject')} (${ct('optional')})`}
                                    </Label>
                                    <Input
                                        id="subject"
                                        value={subject}
                                        onChange={e => setSubject(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="content">
                                        {`${ct('message')} (${ct('optional')})`}
                                    </Label>
                                    <ReactQuill
                                        id="content"
                                        theme="snow"
                                        value={content}
                                        onChange={v => setContent(v)}
                                    />
                                </div>
                            </CardContent>
                        </Card>
                    </Col>
                </Row>
            </main>
        </>
    );
}
