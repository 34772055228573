import React from 'react';

import { NeoFormComponent } from '@/components/neoform/NeoFormComponent';
import { CardCollapse } from '@/components/CardCollapse';
import { type Props as CardCollapseProps } from '@/components/CardCollapse';

import { type TranslationObject, useTranslation } from '@/composables/translation';
import { useNeoForm } from '@/composables/neoform';
import { NeoFormComponentContext } from '@/components/neoform/context/NeoFormComponentContext';
import { CardContent } from '@/components/ui/card';
import { InfoButton } from '@/components/InfoButton';
import { cn } from '@/lib/utils';
import { Row } from '@/components/ui/row';

interface Props {
    prefix: string;
    rows: any[][];
    endSlot?: React.ReactNode;
    collapseProps?: CardCollapseProps;
    last?: boolean;
    private?: boolean;
    name?: TranslationObject;
    info?: TranslationObject;
}

export function NeoFormSection(props: Props) {
    const { to } = useTranslation();
    const {
        form,
        component,
        hasChildFieldPermission
    } = useNeoForm();
    const name = to(props.name, '');
    const info = to(props.info);

    // Register section meta
    form?.setMeta?.([...(component?.path ?? []), props.prefix, 'meta'].filter((p) => !!p).join('.'), {
        title: props.name,
        showInOutline: !props.private
    });

    // Hide private sections and sections without permissions
    if (
        form?.info?.is_public &&
        (!!props.private || !hasChildFieldPermission([props.prefix]))
    ) {
        return null;
    }

    return (
        <NeoFormComponentContext.Provider
            value={{
                name: props.prefix,
                path: [...(component?.path ?? []), props.prefix]
            }}
        >
            <section>
                <CardCollapse
                    {...props.collapseProps}
                    title={name}
                    cardProps={{
                        ...props.collapseProps?.cardProps,
                        className: cn('', props.collapseProps?.cardProps?.className)
                    }}
                    titleSlot={
                        info
                            ? <InfoButton
                                className="tw-ml-2 hover:tw-bg-slate-200"
                                text={info}
                            />
                            : undefined
                    }
                >
                    <CardContent className={cn(
                        '!tw-p-4 tw-flex tw-flex-col tw-flex-wrap tw-gap-3',
                        props.collapseProps?.innerTitle && '!tw-pt-0'
                    )}>
                        {props.rows.map((r, i) =>
                            <Row key={i}>
                                {r.map(({
                                    name,
                                    componentName,
                                    conditionalProps,
                                    titleType,
                                    title,
                                    description,
                                    info,
                                    ...extraProps
                                }, j) =>
                                    <NeoFormComponent
                                        key={
                                            `${props.prefix ? `${props.prefix}.` : ''}${name}.${i}.${j}`
                                        }
                                        name={name}
                                        componentName={componentName}
                                        conditionalProps={conditionalProps}
                                        titleType={titleType}
                                        title={title}
                                        description={description}
                                        info={info}
                                        last={i === props.rows.length - 1}
                                        {...extraProps}
                                    />
                                )}
                            </Row>
                        )}
                        {props.endSlot}
                    </CardContent>
                </CardCollapse>
            </section>
        </NeoFormComponentContext.Provider>
    );
}
