import React from 'react';
import {
    NeoFormComponent,
    type NeoFormComponentProps
} from '@/components/neoform/NeoFormComponent';
import { combine, useTranslation } from '@/composables/translation';
import { NeoFormTitleType } from '@/components/neoform/NeoFormComponentPreface';
import { useNeoForm } from '@/composables/neoform';
import { DateTime } from 'luxon';
import { Row } from '@/components/ui/row';

export interface UserChild {
    user?: {
        name: string;
        firstname: string;
        gender: string;
    };
    dateOfBirth?: DateTime;
    autonomous?: boolean;
    working?: boolean;
    graduated?: boolean;
}

interface Props {
    parentalTime?: boolean;
}

export function UserChildDivorce(props: NeoFormComponentProps & Props) {
    const { ct } = useTranslation();
    const {
        hookForm,
        getChildFieldName
    } = useNeoForm();
    let [
        birthCertificateLang,
        tribunalRestriction,
        dateOfBirth
    ] = hookForm?.watch([
        getChildFieldName('birthCertificateLang'),
        getChildFieldName('tribunalRestriction'),
        getChildFieldName('dateOfBirth')
    ]) ?? [];
    birthCertificateLang ??= false;
    tribunalRestriction ??= false;
    if (typeof dateOfBirth === 'string') {
        dateOfBirth = DateTime.fromISO(dateOfBirth);
    }
    const isAdult = (dateOfBirth?.diffNow('years').years ?? 0) < -18;
    return (
        <>
            <Row>
                <NeoFormComponent
                    name="user"
                    componentName="User"
                    noRelation
                    last
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="dateOfBirth"
                    componentName="InputDate"
                    title={combine('common.birthday')}
                    titleType={NeoFormTitleType.LABEL}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="birthCertificate"
                    componentName="InputDropContainer"
                    title={combine('neoform.user-child.birth-certificate')}
                    display={!isAdult}
                    maxFiles={1}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="birthCertificateLang"
                    componentName="InputCheckbox"
                    title={combine('neoform.user-child.certificate-other-lang')}
                    display={!isAdult}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="tradBirthCertificate"
                    componentName="InputDropContainer"
                    title={combine('neoform.user-child.birth-certificate-translated')}
                    display={!isAdult && birthCertificateLang}
                    maxFiles={1}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="tribunalRestriction"
                    componentName="InputCheckbox"
                    title={combine('neoform.user-child.subject-judgement')}
                    display={!isAdult}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="tribunalRestrictionDocument"
                    componentName="InputDropContainer"
                    title={combine('neoform.user-child.judgement-documents')}
                    display={!isAdult && tribunalRestriction}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="autonomous"
                    componentName="InputCheckbox"
                    title={combine('neoform.user-child.checklist-0')}
                    display={isAdult}
                    last
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="graduated"
                    componentName="InputCheckbox"
                    title={combine('neoform.user-child.checklist-1')}
                    display={isAdult}
                    last
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="working"
                    componentName="InputCheckbox"
                    title={combine('neoform.user-child.checklist-2')}
                    display={isAdult}
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="address"
                    componentName="Address"
                    title={combine('neoform.address.address')}
                    display={isAdult}
                    displayPreface={false}
                    country
                    contact
                />
            </Row>
            <Row>
                <NeoFormComponent
                    name="parentingTimeSharing"
                    componentName="InputSelect"
                    display={props.parentalTime === true}
                    title={combine('neoform.user-child.parent-time-sharing')}
                    last
                    options={[...Array(6)].map((_, i) => ({
                        value: i + 1,
                        label: `${ct('time')} - ${i + 1}`
                    }))}
                />
            </Row>
        </>
    );
}
