import React from 'react';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import { useNeoForm } from '@/composables/neoform';
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';
import { Checkbox } from '@/components/ui/checkbox';

interface CheckboxOption {
    label: TranslationObject;
    value: string;
}

interface Props {
    switch?: boolean;
    options: CheckboxOption[];
}

export function InputCheckboxList(props: NeoFormComponentProps & Props) {
    const { to } = useTranslation();
    const { form, id, getValidationRules } = useNeoForm();
    const validate = getValidationRules(props);
    return (
        <FormField
            name={id}
            defaultValue={props.default}
            render={({ field }) => {
                const value: string[] = field.value ?? [];
                return (
                    <FormItem>
                        <FormControl>
                            <div
                                className="tw-flex tw-flex-col tw-gap-3"
                                ref={(ref) => {
                                    field.ref(ref);
                                    form?.ref(id, ref);
                                }}
                            >
                                {props.options.map((option) =>
                                    <div key={option.value} className="tw-flex tw-items-center">
                                        {React.createElement(
                                            (props.switch ? Switch : Checkbox) as typeof Checkbox,
                                            {
                                                id: `${id}-${option.value}`,
                                                className: 'tw-mr-2 tw-mt-[2px]',
                                                disabled: props.readonly,
                                                ...field,
                                                checked: value.includes(option.value),
                                                onCheckedChange: (checked) => {
                                                    const newValue = new Set(value);
                                                    if (checked) {
                                                        newValue.add(option.value);
                                                    } else {
                                                        newValue.delete(option.value);
                                                    }
                                                    const arr = Array.from(newValue);
                                                    field.onChange(arr);
                                                    form?.set(id, arr);
                                                }
                                            }
                                        )}
                                        <Label
                                            htmlFor={`${id}-${option.value}`}
                                            dangerouslySetInnerHTML={{ __html: to(option.label) }}
                                        />
                                    </div>
                                )}
                            </div>
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                );
            }}
            rules={{ validate }}
        />
    );
}
