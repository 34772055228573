import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';

import { cn } from '@/lib/utils';

const Checkbox = React.forwardRef<
    React.ElementRef<typeof CheckboxPrimitive.Root>,
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({
    className,
    ...props
}, ref) => (
    <CheckboxPrimitive.Root
        ref={ref}
        className={cn(
            'tw-peer tw-h-4 tw-w-4 tw-shrink-0 tw-rounded-sm tw-border tw-border-primary tw-shadow',
            'focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring',
            'disabled:tw-cursor-not-allowed disabled:tw-opacity-50 data-[state=checked]:tw-bg-primary',
            'data-[state=checked]:tw-text-primary-foreground',
            className
        )}
        {...props}
    >
        <CheckboxPrimitive.Indicator
            className={cn('tw-flex tw-items-center tw-justify-center tw-text-current')}
        >
            <CheckIcon className="tw-h-4 tw-w-4"/>
        </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
