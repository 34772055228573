import React, { type ForwardedRef, useState } from 'react';
import { useLanguage } from '@/composables/language';
import { type Language, type TranslationObject, useTranslation } from '@/composables/translation';
import { Input, type InputProps } from '@/components/ui/input';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { useUserStore } from '@/store/user';

interface Props extends Omit<InputProps, 'value' | 'onChange'> {
    value: TranslationObject;
    onChange?: (value: TranslationObject) => void;
}

export const TextFieldTranslate = React.forwardRef(
    (props: Props, ref: ForwardedRef<HTMLInputElement>) => {
        const { options } = useLanguage({ shortenLabels: true });
        const uiLang = useUserStore(state => state.lang);
        const [lang, setLang] = useState<Language>(uiLang);
        const { to } = useTranslation();
        return (
            <div className="tw-flex">
                <Select
                    value={lang}
                    onValueChange={(value) => setLang(value as Language)}
                >
                    <SelectTrigger className="tw-max-w-[65px] !tw-rounded-r-none tw-mr-[-1px]">
                        <SelectValue />
                    </SelectTrigger>
                    <SelectContent className="!tw-min-w-0">
                        <SelectGroup>
                            {options.map(o =>
                                <SelectItem key={o.value} value={o.value}>
                                    {o.label}
                                </SelectItem>
                            )}
                        </SelectGroup>
                    </SelectContent>
                </Select>
                <Input
                    {...props}
                    ref={ref}
                    className="tw-flex-1 tw-rounded-l-none"
                    value={to(props.value, '', lang)}
                    onChange={(e) => props.onChange?.({
                        ...props.value,
                        [lang]: e.target.value
                    })}
                />
            </div>
        );
    }
);
TextFieldTranslate.displayName = 'TextFieldTranslate';
