import React from 'react';
import ReactQuill from 'react-quill';
import { Editor } from '@tinymce/tinymce-react';
import { useNeoForm } from '@/composables/neoform';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import { Controller } from 'react-hook-form';
import { type ControllerRenderProps } from 'react-hook-form/dist/types/controller';
import { useTranslation } from '@/composables/translation';

const toolbar = [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ direction: 'rtl' }],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ color: [] }, { background: [] }],
    [{ font: [] }],
    [{ align: [] }],
    ['clean']
];

interface Props {
    type?: 'quill' | 'tiny';
}

interface ContentEditorProps {
    id: string;
    type: 'quill' | 'tiny';
    field: ControllerRenderProps;
    readonly?: boolean;
}

function ContentEditor(props: ContentEditorProps) {
    switch (props.type) {
    case 'quill':
        return (
            <ReactQuill
                id={props.id}
                theme="snow"
                modules={{ toolbar }}
                readOnly={props.readonly}
                {...props.field}
                onChange={(value) => props.field.onChange(value)}
            />
        );
    case 'tiny': {
        const { ref, ...field } = props.field;
        return (
            <Editor
                id={props.id}
                tinymceScriptSrc="/tinymce/tinymce.min.js"
                disabled={props.readonly}
                {...field}
                onChange={undefined}
                onEditorChange={(value) => props.field.onChange(value)}
                init={{
                    elementpath: false,
                    plugins: 'link image code table fullscreen preview lists',
                    toolbar:
                        'insertfile a11ycheck undo redo | bold italic underline | ' +
                        'forecolor backcolor | template codesample | ' +
                        'alignleft aligncenter alignright alignjustify | bullist numlist | ' +
                        'link image tinydrive | table | fullscreen | preview',
                    setup: (editor) => {
                        editor.ui.registry.addButton('customInsertButton', {
                            icon: 'new-document',
                            tooltip: 'New Page Portrait',
                            onAction: () => {
                                editor.insertContent('[*portrait*]');
                            }
                        });
                        editor.ui.registry.addButton('customInsertButton1', {
                            icon: 'orientation',
                            tooltip: 'New Page Paysage',
                            onAction: () => {
                                editor.insertContent('[*paysage*]');
                            }
                        });
                    }
                }}
            />
        );
    }
    }
}

export function InputContentEditable(props: Props & NeoFormComponentProps) {
    const { to } = useTranslation();
    const { id, getValidationRules } = useNeoForm();
    const validate = getValidationRules(props);
    return (
        <Controller
            name={id}
            defaultValue={to(props.default)}
            render={({ field }) =>
                <ContentEditor
                    id={id}
                    type={props.type ?? 'quill'}
                    field={{
                        ...field,
                        value: field.value ?? ''
                    }}
                    readonly={props.readonly}
                />
            }
            rules={{ validate }}
        />
    );
}
