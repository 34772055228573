import React, { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    type Case,
    type CaseInfo, type GetCaseDto
} from '@/types/folder';
import { Notes } from '@/components/psj/notes/Notes';
import { Parties } from '@/components/psj/Parties';
import { Row, Col } from '@/components/ui/row';
import { NextStepHistory } from '@/components/psj/NextStepHistory';
import { FolderSummary } from '@/components/psj/headers/FolderSummary';
import { HeaderActionBar } from '@/components/psj/headers/HeaderActionBar';
import { QuickActions } from '@/components/psj/QuickActions';
import {
    getCaseList,
    getMacrosList,
    getNextSteps,
    getOrgGroups,
    getOrgUsers,
    instance
} from '@/composables/api';
import { DocumentList } from '@/components/psj/DocumentList';
import { useError } from '@/composables/error';
import { type User, type UserGroup } from '@/types/api/user';
import { type Note } from '@/types/api/note';
import { type NeoFormData, type NeoFormLayout } from '@/types/neoform';
import { type File } from '@/types/api/files';
import { type NextStepInstance } from '@/types/api/process';
import { type Party } from '@/types/api/party';
import { FolderGroups } from '@/components/psj/headers/FolderGroups';
import { type Macro } from '@/types/api/macro';
import { Combobox } from '@/components/ui/combobox';
import { useOverrideConfig } from '@/composables/override';
import { Label } from '@/components/ui/label';
import { HttpStatusCode } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from '@/composables/translation';

interface Context {
    folder_id: string;
    case?: CaseInfo;
    notes: Note[];
    users: User[];
    groups: UserGroup[];
    macros: Macro[];
    next_steps: NextStepInstance[];
    parties: Party[];
    files: File[];
    input: {
        data: NeoFormData;
        layout?: NeoFormLayout;
    };
    loading: boolean;
    setParties: React.Dispatch<Party[]>;
    setCaseInfo: React.Dispatch<Case>;
    setNotes: React.Dispatch<Note[]>;
    setFiles: React.Dispatch<File[]>;
    setInput: React.Dispatch<NeoFormData>;
}

export const FolderContext = createContext<Context | undefined>(undefined);

export function Folder() {
    const { t } = useTranslation('folder');
    const { handleNetworkError } = useError();
    const params = useParams<{ folder_id: string }>();
    const override = useOverrideConfig();
    const folder_id = params.folder_id ?? '';
    const [tab, setTab] = useState('coms');
    const [nextSteps, setNextSteps] = useState<NextStepInstance[]>([]);
    const [macros, setMacros] = useState<Macro[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [groups, setGroups] = useState<UserGroup[]>([]);
    const [notes, setNotes] = useState<Note[]>([]);
    const [inputLayout, setInputLayout] = useState<NeoFormLayout>();
    const [inputData, setInputData] = useState<NeoFormData>({});
    const [files, setFiles] = useState<File[]>([]);
    const [caseInfo, setCaseInfo] = useState<CaseInfo>();
    const [loading, setLoading] = useState(false);
    const [caseLoading, setCaseLoading] = useState(false);
    const [parties, setParties] = useState<Party[]>([]);
    const [cases, setCases] = useState<Case[]>([]);
    const [selectedCaseId, setSelectedCaseId] = useState<string | null>(null);
    const [isNotFound, setIsNotFound] = useState(false);

    useEffect(() => {
        if (override?.is_customer_portal) {
            return;
        }
        setSelectedCaseId(null);
        setIsNotFound(false);
        getCaseList(folder_id)
            .then((res) => setCases(res.data));
    }, [folder_id]);

    useEffect(() => {
        const controller = new AbortController();
        getCase(controller).then();
        return () => {
            controller.abort();
        };
    }, [folder_id, selectedCaseId]);

    useEffect(() => {
        if (override?.is_customer_portal) {
            return;
        }
        setLoading(true);
        Promise.all([
            getOrgUsers(),
            getOrgGroups(),
            getMacrosList({ limit: 10000 })
        ])
            .then(([users, groups, macros]) => {
                setUsers(users.data);
                setGroups(groups.data);
                setMacros(macros.data);
            })
            .catch(handleNetworkError)
            .finally(() => setLoading(false));
    }, []);

    function getCase(controller?: AbortController) {
        if (selectedCaseId === caseInfo?._id) {
            return Promise.resolve();
        }
        setCaseLoading(true);
        return instance.get<GetCaseDto>(
            override?.urls.get_folder ?? '/v1/psj/case',
            {
                signal: controller?.signal,
                params: { case_or_folder_id: selectedCaseId ?? folder_id }
            }
        )
            .then((res) => {
                const { files, parties, input, layout, ...info } = res.data;
                if (info.product?.ns_list) {
                    info.product.ns_list = info.product.ns_list.sort((a, b) => (a.index ?? 0) - (b.index ?? 0));
                }
                setCaseInfo(info);
                setParties(parties);
                setInputData(input);
                if (!override?.is_customer_portal) {
                    setInputLayout(layout.form_json);
                }
                setFiles(files);
                setSelectedCaseId(info._id);
                if (!override?.is_customer_portal) {
                    const domain_id = info.groups.find(g => g.domain)?._id;
                    return getNextSteps({ limit: 10000, domain_id, default: true })
                        .then((res) => {
                            setNextSteps([
                                ...(
                                    info.product?.ns_list ?? []
                                ), ...res.data
                            ]);
                        });
                }
            })
            .catch((err) => {
                if (err?.response?.status === HttpStatusCode.NotFound) {
                    setIsNotFound(true);
                } else {
                    handleNetworkError(err);
                }
            })
            .finally(() => setCaseLoading(false));
    }

    return (
        <FolderContext.Provider
            value={{
                folder_id,
                case: caseInfo,
                notes,
                users,
                groups,
                macros,
                next_steps: nextSteps,
                parties,
                files,
                input: {
                    data: inputData,
                    layout: inputLayout
                },
                loading: loading || caseLoading,
                setParties,
                setCaseInfo,
                setNotes,
                setFiles,
                setInput: setInputData
            }}
        >
            <main className="!tw-p-2 md:!tw-p-4 tw-flex-1 tw-flex tw-flex-col">
                {!isNotFound && <Row className="tw-gap-y-4">
                    <Col
                        col={!override?.is_customer_portal ? 8 : 12}
                        className="tw-flex tw-flex-col tw-gap-4"
                    >
                        <FolderSummary/>
                        <HeaderActionBar tab={tab} setTab={setTab}/>
                        {tab === 'coms' && <Notes
                            key="notes"
                            options={{
                                pin: !override?.is_customer_portal,
                                transfer: !override?.is_customer_portal
                            }}
                        />}
                        {tab === 'docs' && <DocumentList/>}
                    </Col>
                    {!override?.is_customer_portal &&
                        <Col col={4} className="tw-flex tw-flex-col tw-gap-4 print:tw-hidden">
                            <div>
                                <Label htmlFor="case">Dossier</Label>
                                <Combobox<Case, string>
                                    id="case"
                                    options={cases}
                                    getOptionLabel={(opt) => opt.name}
                                    getOptionValue={(opt) => opt._id}
                                    value={selectedCaseId}
                                    onChange={setSelectedCaseId}
                                />
                            </div>
                            <FolderGroups/>
                            <Parties/>
                            <NextStepHistory/>
                            {/* <NextCalls/> */}
                            <QuickActions/>
                        </Col>
                    }
                </Row>}
                {isNotFound &&
                    <div className="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-justify-center">
                        <FontAwesomeIcon
                            className="tw-size-12 tw-text-muted-foreground"
                            icon={faFolderOpen}
                        />
                        <p>{t('not-found')}</p>
                    </div>
                }
            </main>
        </FolderContext.Provider>
    );
}
