import React, {
    useMemo,
    useState,
    useCallback,
    useRef,
    useEffect,
    type MutableRefObject,
    type ForwardedRef
} from 'react';
import _ from 'lodash';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { CaretSortIcon, CheckIcon, Cross1Icon, Cross2Icon } from '@radix-ui/react-icons';
import { Button, type ButtonProps } from '@/components/ui/button';
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem, CommandList
} from '@/components/ui/command';
import { Badge } from '@/components/ui/badge';
import { useTranslation } from '@/composables/translation';
import { useDebounced } from '@/composables/debounce';
import { useControllableState } from '@/composables/controllable';
import { Linear } from '@/components/ui/linear';
import { cn } from '@/lib/utils';
import { assignRef } from '@/composables/utils';

interface ComboboxCommonProps<TValue, TId> extends Omit<ButtonProps, 'value' | 'onChange'> {
    innerRef?: ForwardedRef<HTMLButtonElement | null>;
    options?: TValue[];
    getOptionsAsync?: (search: string) => Promise<TValue[]> | undefined;
    getOptionLabel?: (item: TValue) => string;
    getOptionValue?: (item: TValue) => TId;
    getValueLabel?: (value: TId) => string;
    getCreateValue?: (value: string) => TId;
    renderPrependSlot?: (item: TValue) => React.ReactNode;
    groupBy?: (item: TValue) => string;
    clearable?: boolean;
    placeholder?: string;
    searchPlaceholder?: string;
    className?: string;
    creatable?: boolean;
    uppercase?: boolean;
    error?: boolean;
    onCreate?: (search: string) => void;
}

type ComboboxValueProps<TValue, TId> = {
    multiple?: false;
    value?: TId | null;
    onChange?: (value: TId | null, option: TValue | null) => void;
} | {
    multiple: true;
    sortValue?: boolean;
    value?: TId[] | null;
    onChange?: (value: TId[] | null, options: TValue[] | null) => void;
};

export type ComboboxProps<TValue, TId> =
    ComboboxCommonProps<TValue, TId> & ComboboxValueProps<TValue, TId>;

export function Combobox<TValue, TId = TValue>(
    props: ComboboxProps<TValue, TId>
) {
    const { ct } = useTranslation();
    const commandRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useControllableState([], props.options);

    const getOptions = useCallback(
        (search: string) => {
            setLoading(true);
            props.getOptionsAsync?.(search)
                ?.then((opts) => setOptions(opts))
                ?.finally(() => setLoading(false));
        },
        [props.getOptionsAsync]
    );

    const isAsync = !!props.getOptionsAsync;
    const getOptionsAsync = useDebounced({
        func: getOptions,
        timeout: 1000
    });
    const isGrouped = !!props.groupBy;
    const grouped = useMemo(
        () => props.groupBy ? _.groupBy(options, props.groupBy) : {},
        [options, props.groupBy]
    );
    const {
        id,
        innerRef,
        options: _options,
        getOptionsAsync: _getOptionsAsync,
        getOptionLabel,
        getOptionValue,
        getValueLabel,
        getCreateValue,
        renderPrependSlot,
        groupBy,
        clearable,
        multiple,
        value: __value,
        onChange,
        onCreate,
        placeholder,
        searchPlaceholder,
        className,
        creatable,
        uppercase,
        error,
        ...buttonProps
    } = props;

    useEffect(() => {
        setOptions(props.options ?? []);
    }, [props.options]);

    useEffect(() => {
        if (isAsync) {
            getOptionsAsync(search);
        }
    }, [search]);

    function onClickValue(value: TId) {
        if (props.multiple) {
            const selected = _.xor([
                ...(
                    props.value ?? []
                )
            ], [value]);
            if (props.sortValue) {
                // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
                selected.sort();
            }
            props.onChange?.(
                selected,
                selected.map<TValue>((val) => option(val) as TValue)
                    .filter(Boolean)
            );
        } else {
            props.onChange?.(value, option(value));
        }
    }

    function toValue(val: string): TId {
        if (!Number.isNaN(Number(val))) {
            return Number(val) as TId;
        }
        if (val === 'true' || val === 'false') {
            return Boolean(val) as TId;
        }
        if (val.startsWith('{') || val.startsWith('[')) {
            return JSON.parse(val);
        }
        return val as TId;
    }

    function _value(opt?: TValue): TId | null {
        if (opt == null) {
            return null;
        }
        if (props.getOptionValue) {
            return props.getOptionValue(opt);
        }
        return (
            opt as unknown as TId
        ) ?? null;
    }

    const value = useCallback(_.memoize(_value), [options, props.getOptionValue]);

    function compare(opt: TValue, val: TId | null) {
        const v = value(opt);
        // eslint-disable-next-line eqeqeq
        return _.isEqual(v, val) || v == val;
    }

    function _stringValue(val: TId | null): string {
        return typeof val === 'object' ? JSON.stringify(val) : _.toString(val);
    }

    const stringValue = useCallback(_.memoize(_stringValue), [value]);

    function _label(opt: TValue | null) {
        if (opt == null) {
            return '';
        }
        if (props.getOptionLabel) {
            return props.getOptionLabel(opt);
        }
        return _.toString(opt);
    }

    const label = useCallback(_.memoize(_label), [options, props.getOptionValue]);

    function _valueLabel(val: TId | null): string {
        const opt = option(val);
        if (opt != null) {
            return label(opt);
        } else if (val != null && props.getValueLabel) {
            return props.getValueLabel(val);
        }
        return _.toString(val);
    }

    const valueLabel = useCallback(_.memoize(_valueLabel), [options, props.getOptionLabel, props.getValueLabel]);

    function _prependSlot(opt: TValue) {
        return renderPrependSlot?.(opt);
    }

    const prependSlot = useCallback(_.memoize(_prependSlot), [options, props.renderPrependSlot]);

    function option(value: TId | null): TValue | null {
        return options.find(o => compare(o, value)) ?? null;
    }

    function filter(val: string, search: string) {
        return Number(valueLabel(toValue(val)).toLowerCase()
            .includes(search.toLowerCase()));
    }

    function selected(val: TId | null) {
        if (val == null) {
            return false;
        }
        if (props.multiple && Array.isArray(props.value)) {
            return props.value.includes(val);
        }
        return _.isEqual(props.value, val);
    }

    function renderOption(opt: TValue) {
        const _value = value(opt) as TId;
        const _label = label(opt);
        return (
            <CommandItem
                key={stringValue(_value)}
                value={stringValue(_value)}
                disabled={false}
                onSelect={() => {
                    onClickValue(_value);
                    setOpen(false);
                }}
            >
                {prependSlot(opt)}
                {_label}
                <CheckIcon
                    className={cn(
                        'tw-ml-auto tw-h-4 tw-w-4',
                        selected(_value)
                            ? 'tw-opacity-100'
                            : 'tw-opacity-0'
                    )}
                />
            </CommandItem>
        );
    }

    return (
        <Popover open={open} modal={true} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    {...buttonProps}
                    ref={(ref) => {
                        if (props.innerRef) {
                            assignRef<HTMLButtonElement | null>(props.innerRef, ref);
                        }
                    }}
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    aria-invalid={props.error ? 'true' : 'false'}
                    className={cn(
                        '!tw-px-3 tw-justify-between tw-h-auto !tw-flex tw-w-full',
                        props.className
                    )}
                >
                    <div className={cn(
                        'tw-font-normal',
                        props.multiple && 'tw-flex tw-flex-wrap tw-gap-2',
                        !props.multiple && 'tw-truncate'
                    )}>
                        {!props.multiple && props.value != null &&
                            <span>{valueLabel(props.value)}</span>
                        }
                        {(
                            (
                                !props.multiple && props.value == null
                            ) ||
                                (
                                    props.multiple && (
                                        props.value?.length ?? 0
                                    ) <= 0
                                )
                        ) &&
                            <span className="tw-text-muted-foreground">
                                {props.placeholder ?? ct('select.hint')}
                            </span>
                        }
                        {props.multiple && Array.isArray(props.value) && (
                            props.value ?? []
                        ).map(
                            (v, i) => <Badge
                                key={i}
                                className="tw-pl-1 tw-flex tw-items-center"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onClickValue(v);
                                }}
                            >
                                <Cross2Icon
                                    className="tw-mr-1"
                                />
                                {valueLabel(v)}
                            </Badge>
                        )}
                    </div>
                    <div className="tw-flex">
                        {props.clearable && props.value != null &&
                            (
                                !Array.isArray(props.value) ||
                                (Array.isArray(props.value) && props.value.length > 0)
                            ) &&
                            <Cross1Icon
                                className="tw-ml-2 tw-opacity-50 hover:tw-opacity-100"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    props.onChange?.(
                                        (props.multiple ? [] : null) as any,
                                        (props.multiple ? [] : null) as any
                                    );
                                }}
                            />}
                        <CaretSortIcon className="tw-ml-2 tw-h-4 tw-w-4 tw-shrink-0 tw-opacity-50"/>
                    </div>
                </Button>
            </PopoverTrigger>
            <PopoverContent
                className={cn(
                    '!tw-p-0',
                    'tw-w-[var(--radix-popover-trigger-width)]'
                )}
                align="start"
            >
                <Command
                    ref={commandRef}
                    shouldFilter={!isAsync}
                    filter={filter}
                    onKeyDown={(e) => {
                        if (e.code !== 'Tab' && e.code !== 'Enter') {
                            return;
                        }
                        const el = commandRef.current?.querySelector('[cmdk-item=""][aria-selected="true"]');
                        const value = el?.attributes?.getNamedItem('data-value')?.value;
                        if (value) {
                            onClickValue(toValue(value));
                            setOpen(false);
                        } else if (props.creatable && !onCreate) {
                            const searchValue = props.uppercase
                                ? search.toUpperCase()
                                : search;
                            const value = props.getCreateValue
                                ? props.getCreateValue(searchValue)
                                : toValue(searchValue);
                            onClickValue(value);
                            setOpen(false);
                            if (!isAsync) {
                                setSearch('');
                            }
                        } else if (props.creatable && onCreate) {
                            const searchValue = props.uppercase
                                ? search.toUpperCase()
                                : search;
                            onCreate(searchValue);
                            setOpen(false);
                        }
                    }}
                >
                    <CommandInput
                        className="tw-h-9"
                        placeholder={props.searchPlaceholder ?? ct('select.search-hint')}
                        value={search}
                        onValueChange={setSearch}
                    />
                    {loading && <Linear className="tw-h-[2px]"/>}
                    <CommandList>
                        <CommandEmpty>
                            {search && props.creatable
                                ? `${ct('add')} "${props.uppercase ? search.toUpperCase() : search}"`
                                : ct('select.no-results')
                            }
                        </CommandEmpty>
                        {!isGrouped && <CommandGroup>
                            {options.map(renderOption)}
                        </CommandGroup>}
                        {isGrouped &&
                            Object.entries(grouped)
                                .map(([group, options]) => (
                                    <CommandGroup
                                        key={group}
                                        heading={
                                            <span className="tw-text-sm tw-text-primary">
                                                {group}
                                            </span>
                                        }
                                    >
                                        {options.map(renderOption)}
                                    </CommandGroup>
                                ))
                        }
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
}
