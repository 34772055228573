import { CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import React, { useMemo, useState } from 'react';
import { combine, type TranslationObject, useTranslation } from '@/composables/translation';
import { type UpdateGroupDto, type UserGroup } from '@/types/api/user';
import { CrudInputType, type CrudSchema, CrudTable } from '@/components/ui/crud-table';
import { getOrgGroups, postCreateGroup, putUpdateGroup } from '@/composables/api';
import BitSet from 'bitset';
import { Permission, PERMISSION_LIST, PERMISSION_VALUES } from '@/composables/permissions';
import { useGroups } from '@/composables/groups';
import { BadgeListCell, CheckboxCell } from '@/components/ui/cells';

type Group = Omit<UpdateGroupDto, 'permissions'> & { permissions: string[] };

const PermissionCell = BadgeListCell<Group, string>({
    getter: ({ cell }) => (
        cell.getValue<string[]>() ?? []
    ).map(p => Permission[Number(p)]),
    truncateCount: 2,
    badgeProps: {
        size: 'sm'
    },
    className: 'tw-max-w-[500px]'
});

export function Groups() {
    const { t, to } = useTranslation('settings.groups');
    const [groups, setGroups] = useState<UserGroup[]>([]);
    const { domains } = useGroups({ groups });

    const categories = [
        {
            value: 'speciality',
            label: combine('settings.groups.categories.specialty')
        }
    ];
    const schema = useMemo<CrudSchema<Group>>(() => [
        {
            id: 'group_id',
            type: CrudInputType.TEXT,
            name: t('table.id'),
            readonly: true,
            update: false,
            columnDef: {
                id: 'group_id',
                header: t('table.id'),
                accessorKey: 'group_id'
            }
        },
        {
            id: 'name',
            type: CrudInputType.TEXT,
            name: t('table.name'),
            translate: true,
            required: true,
            columnDef: {
                id: 'name',
                header: t('table.name'),
                accessorKey: 'name',
                cell: ({ cell }) => to(cell.getValue<TranslationObject>())
            }
        },
        {
            id: 'category',
            type: CrudInputType.SELECT,
            name: t('table.category'),
            options: [
                {
                    value: 'speciality',
                    label: combine('settings.groups.categories.specialty')
                }
            ],
            getOptionValue: (opt) => opt.value,
            getOptionLabel: (opt) => to(opt.label),
            clearable: true,
            columnDef: {
                id: 'category',
                header: t('table.category'),
                accessorKey: 'category',
                cell: ({ cell }) => to(categories.find(c => c.value === cell.getValue<string>())?.label)
            }
        },
        {
            id: 'domain',
            type: CrudInputType.CHECKBOX,
            name: t('table.use-as-domain'),
            columnDef: {
                id: 'domain',
                header: t('table.use-as-domain'),
                accessorKey: 'domain',
                cell: CheckboxCell
            }
        },
        {
            id: 'parent',
            type: CrudInputType.SELECT,
            name: t('table.domain'),
            options: domains,
            getOptionValue: opt => opt._id.$oid,
            getOptionLabel: opt => to(opt.name),
            columnDef: {
                id: 'parent',
                header: t('table.domain'),
                accessorKey: 'parent',
                cell: ({ cell }) => to(domains.find(d => d._id.$oid === cell.getValue<string>())?.name)
            }
        },
        {
            id: 'permissions',
            type: CrudInputType.TREE,
            name: t('table.permissions'),
            nodes: PERMISSION_LIST,
            maximal: true,
            columnDef: {
                id: 'permissions',
                header: t('table.permissions'),
                accessorKey: 'permissions',
                cell: PermissionCell
            }
        }
    ], [domains]);

    return (
        <>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
            </CardHeader>
            <CardContent>
                <CrudTable<Group, 'group_id'>
                    idKey="group_id"
                    schema={schema}
                    initialState={{ columnVisibility: { group_id: false } }}
                    onRead={() =>
                        getOrgGroups()
                            .then((res) => {
                                setGroups(res.data);
                                return res.data.map<Group>((g) => (
                                    {
                                        ...g,
                                        parent: g.parent?.$oid,
                                        group_id: g._id.$oid,
                                        permissions: BitSet.fromHexString(g.permissions || '0')
                                            .toArray()
                                            .map(i => String(i))
                                    }
                                ));
                            })
                    }
                    onCreate={(value) =>
                        postCreateGroup({
                            ...value,
                            category: value.category ?? '',
                            permissions: value.permissions
                                .filter(p => PERMISSION_VALUES.includes(Number(p)))
                                .map(p => Permission[Number(p)])
                        }).then()
                    }
                    onUpdate={(value) =>
                        putUpdateGroup({
                            ...value,
                            category: value.category ?? '',
                            permissions: value.permissions
                                .filter(p => PERMISSION_VALUES.includes(Number(p)))
                                .map(p => Permission[Number(p)])
                        }).then()
                    }
                />
            </CardContent>
        </>
    );
}
