export function writeClipboardText(text: string) {
    return navigator.clipboard.writeText(text);
}

export function readClipboardText() {
    return navigator.clipboard.readText();
}

export function writeClipboardJSON(data?: any) {
    return writeClipboardText(JSON.stringify(data) ?? '');
}

export function readClipboardJSON() {
    return new Promise<any>((resolve, reject) => {
        readClipboardText()
            .then((text) => resolve(JSON.parse(text)))
            .catch(() => reject());
    });
}
