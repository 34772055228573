import React from 'react';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import { useNeoForm } from '@/composables/neoform';
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Checkbox } from '@/components/ui/checkbox';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';
import { cn } from '@/lib/utils';

interface Props {
    label: TranslationObject;
    switch?: boolean;
    size?: 'medium' | 'small';
    color?: string;
}

export function InputCheckbox(props: NeoFormComponentProps & Props) {
    const { to } = useTranslation();
    const { form, id, getValidationRules } = useNeoForm();
    const validate = getValidationRules(props);
    const hasColor = !!props.color;
    const label = props.label || props.title;
    return (
        <div
            className={cn({
                'tw-p-4 tw-rounded-md': hasColor,
                [`bg-${props.color}`]: hasColor
            })}
        >
            <FormField
                name={id}
                defaultValue={props.default}
                render={({ field }) => {
                    return (
                        <FormItem>
                            <FormControl>
                                <div className="tw-flex tw-items-center tw-min-h-[36px]">
                                    {React.createElement(
                                        (props.switch ? Switch : Checkbox) as typeof Checkbox,
                                        {
                                            id,
                                            className: 'tw-mt-[2px]',
                                            disabled: props.readonly,
                                            ...field,
                                            ref: (ref) => {
                                                field.ref(ref);
                                                form?.ref(id, ref);
                                            },
                                            value: field.value || false,
                                            checked: field.value || false,
                                            onCheckedChange: (checked) => {
                                                field.onChange(checked);
                                                form?.set(id, checked);
                                            }
                                        }
                                    )}
                                    <Label
                                        className="tw-pl-2"
                                        htmlFor={id}
                                        dangerouslySetInnerHTML={{ __html: to(label) }}
                                    />
                                </div>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    );
                }}
                rules={{ validate }}
            />
        </div>
    );
}
