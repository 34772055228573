import React from 'react';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import { type TranslationObject, useTranslation } from '@/composables/translation';

interface Props {
    html: TranslationObject;
}

export function HTML(props: NeoFormComponentProps & Props) {
    const { to } = useTranslation();
    return (
        <div dangerouslySetInnerHTML={{ __html: to(props.html) }}></div>
    );
}
