import {
    type IntervalObject,
    type IntervalType
} from '@/components/WeekSelection/WeekSelection';
import {
    type CalendarConfig,
    type CalendarEntry, type ScheduleTaken
} from '@/types/api/calendar';
import { type User } from '@/types/api/user';
import { DateTime, Interval } from 'luxon';
import _ from 'lodash';
import { cartesianProduct, hex2hsl } from '@/composables/utils';
import { type useTranslation } from './translation';
import { cn } from '@/lib/utils';

export const TYPE_COLORS = [
    '4 80% 48%',
    '38 92% 50%',
    '84 81% 44%',
    '160 84% 39%',
    '199 89% 48%',
    '221 83% 53%',
    '262 83% 58%',
    '293 69% 49%',
    '333 71% 51%',
    '347 77% 50%'
] as const;

export function transformIntervals(availabilities: IntervalObject[][]): CalendarEntry[] {
    const client = navigator.userAgent;
    const availabilitiesByUser = _.groupBy(
        availabilities.flat().filter(a => !!a),
        a => a.type
    );
    return Object.entries(availabilitiesByUser).map(([key, avails]) => {
        const [user, type] = key.split('-');
        return Interval.merge(avails.map(a => a.interval))
            .map(i => ({
                user,
                type,
                start: i.start?.toISO({ suppressMilliseconds: true }),
                end: i.end?.toISO({ suppressMilliseconds: true }),
                hidden: false,
                metadata: {
                    client
                }
            }));
    })
        .flat();
}

export function transformUsersConfig(
    users: User[],
    config: CalendarConfig,
    to: ReturnType<typeof useTranslation<any>>['to']
): IntervalType[] {
    const categoryList = config.categories
        .map((c, i) => ({
            ...c,
            index: i,
            id: c.id
        }));
    const userList = users.map((u, i) => ({ index: i, ...u }));
    const intervalTypes = cartesianProduct(userList, categoryList);
    return intervalTypes.map<IntervalType>(
        ([user, category]: [typeof userList[number], typeof categoryList[number]]) => {
            const color = TYPE_COLORS[category.index % TYPE_COLORS.length];
            return {
                id: `${user.id.$oid}-${category.id}`,
                class: cn(
                    'tw-bg-[color:hsl(var(--color)_/_0.3)]',
                    'tw-font-semibold tw-overflow-hidden tw-break-all',
                    'tw-border-solid tw-border-2 tw-border-[color:hsl(var(--color))]'
                ),
                style: {
                    '--color': category.color
                        ? hex2hsl(category.color)
                        : color
                },
                label: user.firstname.charAt(0).toUpperCase() +
                    user.lastname.charAt(0).toUpperCase(),
                tooltip: `${user.firstname} ${user.lastname} — ${to(category.name)}`,
                variants: Object.entries(config.categories)
                    .map(([k, v]) => (
                        {
                            id: Number(k),
                            label: v.name
                        }
                    ))
            };
        }
    );
}

export function transformUsersConfigSchedule(
    users: User[],
    config: CalendarConfig
): IntervalType[] {
    return users.map<IntervalType>((u, index) => ({
        id: u.id.$oid,
        class: cn(
            'tw-bg-[color:hsl(var(--color)_/_0.3)]',
            'tw-font-semibold tw-overflow-hidden tw-break-all',
            'tw-border-solid tw-border-2 tw-border-[color:hsl(var(--color))]'
        ),
        style: {
            '--color': u.color
                ? hex2hsl(u.color)
                : TYPE_COLORS[index % TYPE_COLORS.length]
        },
        variants: config.domains.map(d => d.types).flat()
            .map((t) => ({
                id: t._id
            }))
    }));
}

export function transformCalendarWeek<T extends { start: string; end: string }>(
    weekStartDate: DateTime,
    list: T[],
    getInterval: (obj: T) => Omit<IntervalObject, 'interval'>
): IntervalObject[][] {
    const data = _.groupBy(
        list,
        e => Math.floor(DateTime.fromISO(e.start).diff(weekStartDate, 'days').days)
    );
    // Add missing days
    for (let i = 0; i < 7; i++) {
        if (!data[i]) {
            data[i] = [];
        }
    }
    const intervals = Object.values(data)
        .map<IntervalObject[]>(v => v.map(u => ({
            ...getInterval(u),
            interval: Interval.fromDateTimes(
                DateTime.fromISO(u.start),
                DateTime.fromISO(u.end)
            )
        } as IntervalObject)));
    // Split intervals spanning multiple days
    for (let dayIndex = 0; dayIndex < intervals.length; dayIndex++) {
        const day = intervals[dayIndex];
        day.filter(i =>
            (i.interval.end) >
            (i.interval.start?.startOf('day').plus({ day: 1 }))
        )
            .forEach(i => {
                const start = i.interval.start;
                const nextDayStart = start.startOf('day').plus({ day: 1 });
                const end = i.interval.end;
                i.interval = Interval.fromDateTimes(start, nextDayStart);
                if (dayIndex + 1 < 7) {
                    if (!intervals[dayIndex + 1]) {
                        intervals[dayIndex + 1] = [];
                    }
                    intervals[dayIndex + 1].push({
                        ...i,
                        type: i.type,
                        variant: i.variant,
                        interval: Interval.fromDateTimes(nextDayStart, end)
                    });
                }
            });
    }
    return intervals;
}

export function filterCalendarUsers(users: User[]): User[] {
    return users;
}

export function getScheduleIntervalTooltip(users: User[], t: ScheduleTaken) {
    const user = users.find(u => u.id.$oid === t.users[0].id);
    const client = t.clients?.[0];
    let clientName = [client?.firstname, client?.lastname].filter(Boolean).join(' ');
    if (clientName) {
        clientName = ` - ${clientName}`;
    }
    if (user) {
        return `${user.firstname} ${user.lastname}${
            clientName.length > 0 ? clientName : ''
        }, ${t.title}, #${t.ticket_id}`;
    } else if (t.users[0].name) {
        return `${t.users[0].name}${
            clientName.length > 0 ? clientName : ''
        }, ${t.title}, #${t.ticket_id}`;
    }
    return `${clientName.length > 0 ? `${clientName}, ` : ''}${t.title}, #${t.ticket_id}`;
}

export function transformConfig(config: CalendarConfig): CalendarConfig {
    config.domains.forEach((d) => {
        d._id = String(d._id);
        d.types.forEach((t) => {
            t._id = String(t._id);
        });
    });
    config.global_types?.forEach((t) => {
        t._id = String(t._id);
    });
    return config;
}
