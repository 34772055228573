import * as React from 'react';
import { cn } from '@/lib/utils';

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    prependIcon?: React.ReactNode;
    prependPadless?: boolean;
    prependBorder?: boolean;
    appendIcon?: React.ReactNode;
    appendPadless?: boolean;
    appendBorder?: boolean;
    error?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({
        className,
        type,
        prependIcon,
        prependPadless,
        prependBorder,
        appendIcon,
        appendPadless,
        appendBorder,
        error,
        ...props
    }, ref) => {
        const renderedPrependIcon = prependIcon && (
            <div
                className={cn(
                    'tw-absolute tw-left-[1px] tw-inset-y-[1px]',
                    'tw-flex tw-justify-center tw-items-center',
                    (prependBorder ?? true) && 'tw-border-r',
                    !prependPadless && 'tw-pl-3 tw-pr-2.5'
                )}>
                {prependIcon}
            </div>
        );
        const renderedAppendIcon = appendIcon && (
            <div
                className={cn(
                    'tw-absolute tw-right-[1px] tw-inset-y-[1px] ',
                    'tw-flex tw-justify-center tw-items-center',
                    (appendBorder ?? true) && 'tw-border-l',
                    !appendPadless && 'tw-pr-3 tw-pl-2.5'
                )}
            >
                {appendIcon}
            </div>
        );
        const renderedInput = (
            <input
                type={type}
                ref={ref}
                {...props}
                {...(error && { 'aria-invalid': 'true' })}
                className={cn(
                    'tw-h-9 tw-w-full tw-rounded-md tw-border tw-border-input tw-bg-transparent tw-px-3 tw-bg-white',
                    'tw-py-1 tw-text-sm tw-shadow-sm tw-transition-colors file:tw-border-0 file:tw-bg-transparent',
                    'file:tw-text-sm file:tw-font-medium placeholder:tw-text-muted-foreground',
                    'focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring',
                    'disabled:tw-cursor-not-allowed disabled:tw-opacity-50 disabled:tw-bg-slate-100',
                    'aria-invalid:tw-border-destructive',
                    'aria-invalid:focus-visible:tw-ring-destructive',
                    !!prependIcon && (prependBorder ?? true) && '!tw-pl-12',
                    !!prependIcon && !(prependBorder ?? true) && '!tw-pl-9',
                    !!appendIcon && (appendBorder ?? true) && '!tw-pr-12',
                    !!appendIcon && !(appendBorder ?? true) && '!tw-pr-9',
                    !renderedPrependIcon && !renderedAppendIcon && className
                )}
            />
        );
        if (renderedPrependIcon ?? renderedAppendIcon) {
            return (
                <div className={cn('tw-relative', className)}>
                    {renderedPrependIcon}
                    {renderedInput}
                    {renderedAppendIcon}
                </div>
            );
        }
        return renderedInput;
    }
);
Input.displayName = 'Input';

export { Input };
