import React from 'react';
import {
    NeoFormComponent,
    type NeoFormComponentProps
} from '@/components/neoform/NeoFormComponent';
import { combine } from '@/composables/translation';
import { NeoFormTitleType } from '@/components/neoform/NeoFormComponentPreface';
import { useNeoForm } from '@/composables/neoform';
import { CONTROLS, LEGAL_FORMS, RELATIONSHIPS, YES_NO } from '@/components/utils/translations';
import { Row } from '@/components/ui/row';

interface Props {
    nameTarget?: string;
}

export function StakeholderRecursive(props: NeoFormComponentProps & Props) {
    const { hookForm, getChildFieldName } = useNeoForm();
    let [
        physicalPerson,
        legalForm,
        defactoControl,
        shareholderAgreement,
        votingAgreement
    ] = hookForm?.watch([
        getChildFieldName('personnePhysique'),
        getChildFieldName('formeJuridique'),
        getChildFieldName('defactoControl'),
        getChildFieldName('conventionActionnaires'),
        getChildFieldName('conventionVote')
    ]) ?? [];
    physicalPerson ??= false;
    legalForm ??= 'individuelle';
    defactoControl = defactoControl === 'yes' ?? false;
    shareholderAgreement = shareholderAgreement === 'yes' ?? false;
    votingAgreement = votingAgreement === 'yes' ?? false;
    const showAgreements = ![
        'individuelle',
        'fiducie-exploitant-unit',
        'fiducie-exploitant-no-unit'
    ]
        .includes(legalForm);
    const showTotalShares = ![
        'individuelle',
        'fiducie-exploitant-no-unit'
    ]
        .includes(legalForm);
    return (
        <div className="tw-flex tw-flex-col tw-gap-1">
            <Row>
                <NeoFormComponent
                    name="personnePhysique"
                    componentName="InputCheckbox"
                    label={combine('neoform.physical-person')}
                    col={4}
                />
            </Row>
            {physicalPerson && <>
                <Row>
                    <NeoFormComponent
                        name="lastname"
                        componentName="InputText"
                        titleType={NeoFormTitleType.LABEL}
                        title={combine('common.last-name')}
                        col={3}
                    />
                    <NeoFormComponent
                        name="firstname"
                        componentName="InputText"
                        titleType={NeoFormTitleType.LABEL}
                        title={combine('common.first-name')}
                        col={3}
                    />
                    <NeoFormComponent
                        name="gender"
                        componentName="InputSelect"
                        titleType={NeoFormTitleType.LABEL}
                        title={combine('common.relationship')}
                        col={3}
                        options={RELATIONSHIPS}
                    />
                    <NeoFormComponent
                        name="Birthday"
                        componentName="InputDate"
                        titleType={NeoFormTitleType.LABEL}
                        title={combine('common.birthday')}
                        col={3}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="numberActionAssujetti"
                        componentName="InputNumber"
                        titleType={NeoFormTitleType.LABEL}
                        title={combine('neoform.total-shares-subject')}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="address"
                        componentName="Address"
                        title={combine('neoform.address.address')}
                        displayPreface={false}
                        country
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="otherName"
                        componentName="InputText"
                        title={{
                            en: 'Other Name',
                            fr: 'Autre nom'
                        }}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="addressCanada"
                        componentName="InputRadio"
                        titleType={NeoFormTitleType.DEFAULT}
                        title={{
                            en: 'Residential Address in Canada?',
                            fr: 'Adresse de domicile au Canada?'
                        }}
                        default="yes"
                        options={YES_NO}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="otherAddress"
                        componentName="InputText"
                        titleType={NeoFormTitleType.LABEL}
                        title={combine('neoform.address.address')}
                        textarea
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="controlType"
                        componentName="InputSelect"
                        titleType={NeoFormTitleType.LABEL}
                        title={combine('neoform.stakeholder.control.title')}
                        default="direct"
                        options={CONTROLS}
                        col={4}
                    />
                    <NeoFormComponent
                        name="beneficiaryStartDate"
                        componentName="InputDate"
                        titleType={NeoFormTitleType.LABEL}
                        title={combine('neoform.stakeholder.date-start')}
                        col={4}
                    />
                    <NeoFormComponent
                        name="beneficiaryRetraitDate"
                        componentName="InputDate"
                        titleType={NeoFormTitleType.LABEL}
                        title={combine('neoform.stakeholder.date-end')}
                        col={4}
                    />
                </Row>
            </>}
            {!physicalPerson && <>
                <Row>
                    <NeoFormComponent
                        name="nom"
                        componentName="InputText"
                        title={combine('common.name')}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="formeJuridique"
                        componentName="InputSelect"
                        title={combine('neoform.legal-form')}
                        default="individuelle"
                        options={LEGAL_FORMS}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="other"
                        componentName="InputText"
                        title={combine('neoform.other')}
                        placeholder={combine('neoform.specify')}
                        display={legalForm === 'autre'}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="categoryActions"
                        componentName="CategoryActions"
                        title={combine('neoform.share-classes')}
                        display={legalForm === 'action'}
                        sumTargets={{
                            voting: getChildFieldName('numberActionVote'),
                            total: getChildFieldName('numberActionAssujetti')
                        }}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="numberActionVote"
                        componentName="InputNumber"
                        title={combine('neoform.voting-shares')}
                        default={0}
                        display={legalForm === 'action'}
                        readonly
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="numberActionAssujetti"
                        componentName="InputNumber"
                        title={combine('neoform.total-shares')}
                        default={0}
                        display={showTotalShares}
                        readonly={legalForm === 'action'}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="conventionActionnaires"
                        componentName="InputRadio"
                        title={combine('neoform.shareholder-agreement')}
                        default="no"
                        display={showAgreements}
                        options={YES_NO}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="ACinfosVote"
                        componentName="InputText"
                        title={combine('neoform.voting-info')}
                        display={showAgreements && shareholderAgreement}
                        textarea
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="ACinfosVoteAttachement"
                        componentName="InputDropContainer"
                        title={combine('neoform.agreement-attachments')}
                        display={showAgreements && shareholderAgreement}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="conventionVote"
                        componentName="InputRadio"
                        title={combine('neoform.voting-agreement')}
                        default="no"
                        display={showAgreements}
                        options={YES_NO}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="CVinfosVote"
                        componentName="InputText"
                        title={combine('neoform.voting-info')}
                        display={showAgreements && votingAgreement}
                        textarea
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="CVinfosVoteAttachemnt"
                        componentName="InputDropContainer"
                        title={combine('neoform.agreement-attachments')}
                        display={showAgreements && votingAgreement}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="JVMtotale"
                        componentName="InputNumber"
                        title={combine('neoform.market-value')}
                        display={legalForm !== 'individuelle'}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="defactoControl"
                        componentName="InputRadio"
                        title={combine('neoform.de-facto-control')}
                        default="no"
                        display={legalForm !== 'individuelle'}
                        options={YES_NO}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="founders"
                        componentName="Users"
                        title={combine('neoform.founders')}
                        display={legalForm !== 'individuelle' && defactoControl}
                        noRelation={true}
                    />
                </Row>
                <Row>
                    <NeoFormComponent
                        name="actionnaires"
                        componentName="Actionnaires2"
                        title={combine('neoform.stakeholders')}
                        display={legalForm !== 'individuelle'}
                        last
                    />
                </Row>
            </>}
        </div>
    );
}
