import React, { useContext, useMemo, useRef, useState } from 'react';
import {
    type CrudApi,
    CrudInputType,
    type CrudSchema,
    type CrudSelectInputOptions,
    CrudTable
} from '@/components/ui/crud-table';
import { type File } from '@/types/api/files';
import { Button } from '@/components/ui/button';
import { DownloadIcon, EyeOpenIcon, PaperPlaneIcon, UploadIcon } from '@radix-ui/react-icons';
import {
    deleteDocuments,
    getDocuments,
    postCreateForm,
    postFormClients, postFormClientSendEmails,
    postUpdateDocument,
    postUploadFile
} from '@/composables/api';
import type { ColumnDef } from '@tanstack/react-table';
import type { Case } from '@/types/folder';
import { Badge, type BadgeProps } from '@/components/ui/badge';
import { faCheck, faHourglass, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { DocumentRequest, type DocumentRequestData } from '@/components/psj/DocumentRequest';
import { DialogTrigger } from '@/components/ui/dialog';
import { FolderContext } from '@/pages/psj/Folder';
import { useError } from '@/composables/error';
import {
    downloadFileURL,
    getInputFileList,
    newStateFromAction,
    normalizeFileName
} from '@/composables/utils';
import { toast } from 'react-toastify';
import { type TranslationObject, combine, useTranslation } from '@/composables/translation';
import { useUserStore } from '@/store/user';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { FilePreview } from '@/components/neoform/helper/FilePreview';
import { Card, CardContent } from '@/components/ui/card';
import { useOverrideConfig } from '@/composables/override';
import { type NeoFormClient, type NeoFormClientDto, type NeoFormLayout } from '@/types/neoform';
import { v4 as uuid } from 'uuid';
import { DateCell, TranslateCell } from '@/components/ui/cells';
import mime from 'mime-types';
import DropZoneWrapper from '../utils/DropZoneWrapper';

const PREVIEW_MIME_TYPES = ['image/', 'application/pdf', 'message/rfc822', 'application/vnd.ms-outlook'];

const statusOptions: Array<{
    value: string;
    label: TranslationObject | string;
    icon?: IconProp;
    variant: BadgeProps['variant'];
}> = [
    {
        value: '0',
        label: combine('psj.documents.status.pending'),
        icon: faHourglass,
        variant: 'warning'
    },
    {
        value: '1',
        label: combine('psj.documents.status.received'),
        icon: faCheck,
        variant: 'success'
    },
    {
        value: '2',
        label: combine('psj.documents.status.expired'),
        icon: faTimes,
        variant: 'destructive'
    },
    {
        value: '3',
        label: combine('psj.documents.status.default'),
        variant: 'secondary'
    }
];

const StatusCell: ColumnDef<File>['cell'] = ({ cell }) => {
    const { to } = useTranslation();
    const value = cell.getValue<string>();
    // eslint-disable-next-line eqeqeq
    const status = statusOptions.find((s) => s.value == value);
    if (!status) {
        return null;
    }
    return (
        <div>
            <Badge className="!tw-rounded-full" size="md" variant={status.variant}>
                {status.icon && <FontAwesomeIcon className="tw-mr-2" icon={status.icon}/>}
                {to(status.label)}
            </Badge>
        </div>
    );
};

const categoryOptions = [
    {
        value: 'req_portal',
        label: combine('psj.documents.categories.req-portal')
    },
    {
        value: 'req_form',
        label: combine('psj.documents.categories.req-form')
    },
    {
        value: 'doc_client',
        label: combine('psj.documents.categories.doc-client')
    },
    {
        value: 'other',
        label: combine('psj.documents.categories.other')
    }
];

export function DocumentList() {
    const { ct, to, t } = useTranslation('psj.documents');
    const { user, lang } = useUserStore(state => ({
        user: state.user,
        lang: state.lang
    }));
    const context = useContext(FolderContext);
    const override = useOverrideConfig();
    const { handleNetworkError } = useError();
    const crudApi = useRef<CrudApi>();
    const fileInput = useRef<HTMLInputElement | null>(null);
    const [requestOpen, setRequestOpen] = useState(false);

    const CategoryCell: ColumnDef<Case>['cell'] = ({ cell }) => {
        const value = cell.getValue<string>();
        const category = categoryOptions.find((c) => c.value === value);
        if (!category) {
            return null;
        }
        return to(category.label);
    };

    const schema = useMemo<CrudSchema<File>>(() => [
        {
            id: '_id',
            type: CrudInputType.TEXT,
            name: t('fields.id'),
            update: false,
            columnDef: {
                id: '_id',
                header: t('fields.id'),
                accessorKey: '_id'
            }
        },
        {
            id: 'name',
            type: CrudInputType.TEXT,
            name: t('fields.name'),
            columnDef: {
                id: 'name',
                header: t('fields.name'),
                accessorKey: 'name'
            }
        },
        {
            id: 'content_type',
            type: CrudInputType.TEXT,
            name: t('fields.content-type'),
            update: false,
            create: false,
            columnDef: {
                id: 'content_type',
                header: t('fields.content-type'),
                accessorFn: (row) => (
                    mime.extension(row.content_type ?? 'application/octet-stream') || ''
                ).toUpperCase()
            }
        },
        {
            id: 'metadata.task.form.input.title',
            type: CrudInputType.TEXT,
            name: t('fields.title'),
            translate: true,
            columnDef: {
                id: 'metadata.task.form.input.title',
                header: t('fields.title'),
                accessorKey: 'metadata.task.form.input.title',
                cell: TranslateCell({ to })
            }
        },
        {
            id: 'category',
            type: CrudInputType.SELECT,
            name: t('fields.category'),
            options: categoryOptions,
            getOptionValue: (opt) => opt.value,
            getOptionLabel: (opt) => to(opt.label),
            columnDef: {
                id: 'category',
                header: t('fields.category'),
                accessorKey: 'category',
                cell: CategoryCell
            }
        } as CrudSelectInputOptions<File, typeof categoryOptions[number]>,
        {
            id: 'generated_document_type',
            type: CrudInputType.TEXT,
            name: t('fields.generated-document-type'),
            columnDef: {
                id: 'generated_document_type',
                header: t('fields.generated-document-type'),
                accessorKey: 'generated_document_type'
            }
        },
        {
            id: 'status',
            type: CrudInputType.SELECT,
            name: t('fields.status'),
            options: statusOptions,
            getOptionValue: (opt) => String(opt.value),
            getOptionLabel: (opt) => to(opt.label),
            columnDef: {
                id: 'status',
                header: t('fields.status'),
                accessorKey: 'status',
                cell: StatusCell
            }
        } as CrudSelectInputOptions<File, typeof statusOptions[number]>,
        {
            id: 'metadata.request.email',
            type: CrudInputType.TEXT,
            name: t('fields.email'),
            columnDef: {
                id: 'email',
                header: t('fields.email'),
                accessorKey: 'metadata.request.email'
            }
        },
        {
            id: 'updated_at',
            type: CrudInputType.TEXT,
            name: t('fields.updated-at'),
            update: false,
            columnDef: {
                id: 'updated_at',
                header: t('fields.updated-at'),
                accessorKey: 'updated_at',
                cell: DateCell
            }
        },
        {
            id: 'created_at',
            type: CrudInputType.TEXT,
            name: t('fields.created-at'),
            update: false,
            columnDef: {
                id: 'created_at',
                header: t('fields.created-at'),
                accessorKey: 'created_at',
                cell: DateCell
            }
        }
    ], []);

    function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
        const files = getInputFileList(e.target);
        Promise.all(files.map(f =>
            postUploadFile(f, {
                target: 'NEODESK_files',
                email: user?.email,
                folder_id: context?.folder_id ?? '',
                case_id: context?.case?._id ?? '',
                category: override?.is_customer_portal ? 'req_portal' : 'other'
            }))
        )
            .then((res) => {
                toast(ct('messages.success'), { type: 'success' });
                context?.setFiles([...(context?.files ?? []), ...res.map(r => r.data)]);
            })
            .catch(handleNetworkError)
            .finally(() => {
                if (fileInput.current) {
                    fileInput.current.value = '';
                }
                setTimeout(() => crudApi.current?.refreshList(), 1000);
            });
    }

    function handleDownloadFile(file: File) {
        downloadFileURL(normalizeFileName(file.name, file.content_type ?? 'application/octet-stream'), file.url);
    }

    function handleRequestFiles(data: DocumentRequestData) {
        const form_json: NeoFormLayout = {
            header: {
                identity: 'NEODESK',
                formType: 'custom-form',
                formName: 'custom-form',
                formTitle: {
                    en: 'Document request',
                    fr: 'Demande de documents'
                },
                formDescription: {},
                config: {
                    hide_document_options: true
                }
            },
            body: [
                {
                    rows: data.files.map((f, idx) => [{
                        name: `file${idx + 1}`,
                        componentName: 'InputDropContainer',
                        title: {
                            en: f.title,
                            fr: f.title
                        },
                        maxFiles: 1
                    }]),
                    header: {
                        legend: {
                            en: 'Required documents',
                            fr: 'Documents requis'
                        },
                        sectionPrefix: 'attachments'
                    }
                }
            ],
            components: {}
        };
        const clients = data.emails.map<NeoFormClientDto>((email) => {
            const party = context?.parties?.find(p => p.email === email);
            return {
                id: uuid(),
                firstname: party?.firstname ?? '',
                lastname: party?.lastname ?? '',
                email,
                secure_2fa: true,
                subject: data.subject ?? '',
                content: data.message ?? '',
                init_public_url: 1,
                permissions: null
            };
        });
        const existingFiles = data.files
            .map(f => f._id as string)
            .filter(Boolean);
        return postCreateForm({
            ticket_id: context?.folder_id ?? '',
            case_id: context?.case?._id ?? '',
            form_id: '669970a9668a60c78cff2bcc',
            form_json,
            lang
        })
            .then((res) =>
                (existingFiles.length > 0
                    ? deleteDocuments(existingFiles)
                    : Promise.resolve()
                ).then(() => res.data.task_id)
            )
            .then((task_id) =>
                postFormClients(task_id, clients)
                    .then((res) => ({ task_id, clients: res.data.clients }))
            )
            .then(({ task_id, clients }) => postFormClientSendEmails(
                task_id,
                clients.map((c: NeoFormClient) => c.id))
            )
            .then(() => {
                toast(ct('messages.success'), { type: 'success' });
                setRequestOpen(false);
            })
            .catch(handleNetworkError);
    }

    return (
        <DropZoneWrapper handleFileUpload={handleFileUpload} hoverBorder={true}>
            <Card>
                <CardContent className="!tw-p-3">
                    <CrudTable<File, '_id'>
                        idKey="_id"
                        apiRef={crudApi}
                        schema={schema}
                        initialState={{ columnVisibility: { _id: false } }}
                        toolbar={<>
                            {!override?.is_customer_portal &&
                                <DocumentRequest
                                    open={requestOpen}
                                    onOpenChange={setRequestOpen}
                                    onSubmit={handleRequestFiles}
                                >
                                    <DialogTrigger asChild>
                                        <Button type="button" className="tw-text-primary" variant="outline">
                                            <PaperPlaneIcon className="tw-mr-2"/>
                                            {t('actions.request')}
                                        </Button>
                                    </DialogTrigger>
                                </DocumentRequest>
                            }
                            <Button
                                type="button"
                                className="tw-text-primary" variant="outline"
                                onClick={() => fileInput.current?.click()}
                            >
                                <UploadIcon className="tw-mr-2" />
                                {t('actions.upload')}
                            </Button>
                        </>}
                        actions={({ row }) =>
                            <>
                                <Tooltip>
                                    <TooltipTrigger asChild>
                                        <Button
                                            type="button" variant="ghost" size="icon"
                                            onClick={() => handleDownloadFile(row.original)}
                                        >
                                            <DownloadIcon />
                                        </Button>
                                    </TooltipTrigger>
                                    <TooltipContent>
                                        {t('actions.download')}
                                    </TooltipContent>
                                </Tooltip>
                                {PREVIEW_MIME_TYPES.some(m => row.original.content_type?.startsWith(m)) &&
                                    <FilePreview file={{
                                        name: row.original.name,
                                        mimeType: row.original.content_type ?? 'application/octet-stream',
                                        url: row.original.url
                                    }}>
                                        <Tooltip>
                                            <DialogTrigger asChild>
                                                <TooltipTrigger asChild>
                                                    <Button
                                                        type="button" variant="ghost" size="icon"
                                                    >
                                                        <EyeOpenIcon/>
                                                    </Button>
                                                </TooltipTrigger>
                                            </DialogTrigger>
                                            <TooltipContent>
                                                {t('actions.preview')}
                                            </TooltipContent>
                                        </Tooltip>
                                    </FilePreview>
                                }
                            </>
                        }
                        readDeps={[context?.folder_id]}
                        list={context?.files ?? []}
                        onChangeList={(value) => context?.setFiles(newStateFromAction(context?.files ?? [], value))}
                        onRead={
                            !override?.is_customer_portal
                                ? () => getDocuments(context?.folder_id ?? '')
                                    .then((res) => res.data
                                        .map((f: File) => ({ ...f, status: String(f.status) }))
                                    )
                                : undefined
                        }
                        onUpdate={
                            !override?.is_customer_portal
                                ? (f) => postUpdateDocument(f).then()
                                : undefined
                        }
                        onDelete={
                            !override?.is_customer_portal
                                ? (files) => deleteDocuments(files.map(f => f._id)).then()
                                : undefined
                        }
                    />
                    <input
                        ref={fileInput}
                        type="file" multiple
                        className="tw-hidden"
                        onChange={handleFileUpload}
                    />
                </CardContent>
            </Card>
        </DropZoneWrapper>
    );
}
