import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FolderContext } from '@/pages/psj/Folder';
import { Note, type NoteOptions } from '@/components/psj/notes/Note';
import { type Note as NoteType } from '@/types/api/note';
import { Spinner } from '@/components/ui/spinner';
import { NoteEdit } from '@/components/psj/notes/NoteEdit';
import { HeaderNotes } from '@/components/psj/headers/HeaderNotes';
import { useMergeState } from '@/composables/merge';
import { useFilter } from '@/composables/filter';
import { ButtonAsync } from '@/components/ui/button-async';
import { instance, postPinNote } from '@/composables/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from '@/composables/translation';
import { cn } from '@/lib/utils';
import { useError } from '@/composables/error';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import _ from 'lodash';
import { useOverrideConfig } from '@/composables/override';
import { HttpStatusCode } from 'axios';

interface Props {
    unassignedNotes?: boolean;
    options?: NoteOptions;
}

export function Notes(props: Props) {
    const { t } = useTranslation('psj.notes');
    const context = useContext(FolderContext);
    const override = useOverrideConfig();
    const { handleNetworkError } = useError();
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [noteVisibility, setNoteVisibility] = useMergeState<Record<string, boolean>>({});
    const [notePinned, setNotePinned,,, count] = useMergeState<Record<string, boolean>>({});
    const [showLoadMore, setShowLoadMore] = useState(true);
    const [parent] = useAutoAnimate({ duration: 500 });
    const unassignedNotes = props.unassignedNotes ?? false;
    const list = useMemo(
        () => (context?.notes ?? []).filter(n => !n.hidden),
        [context?.notes]
    );
    const filteredNotes = useFilter<NoteType>({
        list,
        search,
        dependencies: [count],
        options: {
            ignoreLocation: true,
            includeScore: true,
            includeMatches: true,
            threshold: 0,
            keys: ['note', 'updated_at', 'origin.auth.username'],
            sortFn: (a, b) => {
                const noteA = list[a.idx];
                const noteB = list[b.idx];
                const pinDiff = Number(notePinned[noteB._id] ?? false) - Number(notePinned[noteA._id] ?? false);
                if (pinDiff !== 0) {
                    return pinDiff;
                }
                return noteB.updated_at.localeCompare(noteA.updated_at);
            }
        }
    });

    useEffect(() => {
        setLoading(true);
        handleLoadMoreNotes(true)
            .finally(() => setLoading(false));
    }, [context?.folder_id]);

    useEffect(() => {
        setNotePinned(
            list.reduce((obj, n) => ({ ...obj, [n._id]: n.pinned }), {})
        );
    }, [list]);

    function handlePinNote(note: NoteType, value: boolean) {
        setNotePinned({ [note._id]: value });
        note.pinned = value;
        return postPinNote({ _id: note._id, pinned: value })
            .catch(handleNetworkError);
    }

    function handleLoadMoreNotes(clear = false) {
        return instance.get<{ total: number; count: number; notes: NoteType[] }>(
            override?.urls.get_note_list ?? '/v1/psj/note/list',
            {
                params: {
                    id: context?.folder_id ?? '',
                    verbose: true,
                    unassigned: unassignedNotes,
                    offset: clear ? 0 : (context?.notes.length ?? 0),
                    limit: 50
                }
            }
        )
            .then((res) => {
                const notes = _.uniqBy([
                    ...(clear ? [] : (context?.notes ?? [])),
                    ...res.data.notes
                ], '_id');
                context?.setNotes(notes);
                const totalCount = (clear ? 0 : (context?.notes.length ?? 0)) + res.data.notes.length;
                if (res.data.total === totalCount) {
                    setShowLoadMore(false);
                } else if (clear) {
                    setShowLoadMore(true);
                }
            })
            .catch((err) => {
                if (err?.response?.status !== HttpStatusCode.NotFound) {
                    handleNetworkError(err);
                }
            });
    }

    if (loading) {
        return (
            <div className="tw-flex tw-justify-center tw-p-6">
                <Spinner size="lg" className="tw-text-primary"/>
            </div>
        );
    }

    return (
        <>
            <div className="tw-contents print:tw-hidden">
                {!unassignedNotes && <NoteEdit />}
                <HeaderNotes
                    search={search}
                    onChangeSearch={setSearch}
                    onChangeVisibility={setNoteVisibility}
                />
            </div>
            <div ref={parent} className="tw-flex tw-flex-col tw-gap-3">
                {filteredNotes.map(({ item: note }) => (
                    <Note
                        key={note._id}
                        note={note}
                        visible={noteVisibility[note._id] ?? true}
                        onChangeVisible={(value) => setNoteVisibility({ [note._id]: value })}
                        pinned={notePinned[note._id] ?? false}
                        onChangePinned={(value) => handlePinNote(note, value)}
                        options={props.options}
                    />
                ))}
            </div>
            <ButtonAsync
                className={cn(
                    'tw-self-center !tw-rounded-full tw-text-primary hover:tw-text-primary',
                    'print:tw-hidden', !showLoadMore && 'tw-hidden'
                )}
                variant="outline" size="sm"
                onClick={handleLoadMoreNotes}
            >
                <FontAwesomeIcon
                    className="tw-mr-2 tw-relative tw-top-[1px]"
                    icon={faAngleDown}
                />
                {t('load-more')}
            </ButtonAsync>
        </>
    );
}
