import { type Language, type TranslationObject } from '@/composables/translation';
import { type Props } from '@/components/neoform/NeoFormComponent';
import { type RequiredFieldsOnly } from '@/types/utils';

export type NeoFormMeta = Record<string, any>;

export interface NeoFormData {
    form?: string;
    [key: string]: any;
}

export interface NeoFormPDFOption {
    title: TranslationObject;
    name: string;
    icon: string;
    color: string;
    hasGenDocument: boolean;
    forceGenDocument?: boolean;
    docFilename: string;
    show?: boolean;
    public?: boolean;
    admin?: boolean;
    shorten?: boolean;
    download?: boolean;
    warningAlert?: {
        title?: TranslationObject;
        message?: TranslationObject;
        confirmText?: TranslationObject;
    };
    closePublic?: boolean;
}

export interface NeoFormHeader {
    formName: string;
    formType: string;
    formLanguage?: Language[];
    identity: string;
    formTitle: TranslationObject;
    formDescription?: TranslationObject;
    imageLink?: string;
    imagePath?: string;
    imageWidth?: number;
    config?: any;
    pdfOptions?: NeoFormPDFOption[];
    [key: string]: any;
}

export interface NeoFormSectionHeaderDef {
    legend: TranslationObject;
    sectionPrefix: string;
    sectionName?: TranslationObject;
    sectionDescription?: TranslationObject;
    sectionInfo?: TranslationObject;
    private?: boolean;
}

export interface NeoFormComponentDef extends Props {
}

export type NeoFormSectionRowDef = NeoFormComponentDef[];

export interface NeoFormSectionDef {
    rows: NeoFormSectionRowDef[];
    header: NeoFormSectionHeaderDef;
}

export interface NeoFormLayout {
    header: NeoFormHeader;
    body: NeoFormSectionDef[];
    components: Record<string, NeoFormSectionRowDef[]>;
}

export interface NeoForm {
    _id: string;
    title: string | TranslationObject;
    form_name: string;
    form_type: string;
    form_json: NeoFormLayout;
    org_id: string;
}

export type NeoFormPermissionList = string[] | null;

export interface NeoFormClient {
    id: string;
    email: {
        address: string;
        status?: string[];
    };
    firstname: string;
    lastname: string;
    secure_2fa: boolean;
    init_public_url?: 0 | 1;
    name?: string;
    form_jwt?: string;
    form_permissions: NeoFormPermissionList;
    form_submit_at?: string;
    form_url?: string;
    created_at?: string;
    updated_at?: string;
}

export type NeoFormClientApi = Omit<NeoFormClient, 'id'>;
export type NeoFormClientListApi = Record<string, NeoFormClientApi>;

export type NeoFormClientDto = Omit<
    RequiredFieldsOnly<NeoFormClient>,
    'email' | 'form_permissions'
> &
    {
        email: string;
        subject: string;
        content: string;
        permissions: NeoFormPermissionList;
        init_public_url?: 0 | 1;
    };

export interface NeoFormCreateDto {
    form_id: string;
    ticket_id: string;
    case_id?: string;
    lang: string;
    form_json?: NeoFormLayout;
}

export interface NeoFormFunctionCase {
    condition?: string;
    value: string;
}
export type NeoFormFunction = string | string[] | NeoFormFunctionCase[];

export enum NeoFormEmailQueueStatus {
    SENT = 'sent',
    PENDING = 'pending',
    ERROR = 'error',
    NONE = 'none'
}

export enum NeoFormStatus {
    OPEN = 0,
    COMPLETED = 1,
    CLOSED = 2
}
