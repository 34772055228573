import type React from 'react';

interface Props {
    getProps?: () => any;
    render: (props: any) => React.ReactElement | null;
}

export function WithHooks(props: Props) {
    const childProps = props.getProps?.() ?? {};
    return props.render(childProps);
}
