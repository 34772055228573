import React, { useMemo, useState } from 'react';
import {
    type ColumnDef,
    type ColumnFiltersState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    type PaginationState,
    type RowSelectionState,
    type SortingState,
    type TableOptions,
    type TableState,
    useReactTable,
    type VisibilityState
} from '@tanstack/react-table';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { Pagination, PaginationContent, PaginationItem } from '@/components/ui/pagination';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';
import {
    CaretDownIcon,
    CaretSortIcon,
    CaretUpIcon,
    DoubleArrowLeftIcon,
    DoubleArrowRightIcon,
    DragHandleDots2Icon,
    MagnifyingGlassIcon
} from '@radix-ui/react-icons';
import { useControllableState } from '@/composables/controllable';
import { Skeleton } from '@/components/ui/skeleton';
import { cn } from '@/lib/utils';
import { Label } from '@/components/ui/label';
import { useTranslation } from '@/composables/translation';
import { newStateFromAction } from '@/composables/utils';
import _ from 'lodash';
import { ReactSortable } from 'react-sortablejs';
import { type CrudInputOptions, CrudInputType, InputField } from '@/components/ui/crud-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faFilter } from '@fortawesome/free-solid-svg-icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';

interface DataTableColumnOptions<TOptions = any, TId = any> {
    filter?: CrudInputOptions<TOptions, TId>;
}

export type DataTableColumn<TData, TValue = unknown> = ColumnDef<TData, TValue> & DataTableColumnOptions;

export interface DataTableProps<TData, TValue> extends Partial<TableOptions<TData>> {
    id?: string;
    columns: Array<DataTableColumn<TData, TValue>>;
    data: TData[];
    className?: string;
    state?: Partial<TableState>;
    initialState?: Partial<TableState>;
    rowCount?: number;
    loading?: boolean;
    refetching?: boolean;
    slots?: {
        header?: React.ReactNode;
    };
    enableRowSelectionCheckboxLabel?: boolean;
    enablePagination?: boolean;
    enableRowDragging?: boolean;
    enableRowNumbering?: boolean;
    maxSelectionCount?: number;
    numSkeletonRows?: number;
    onChangeData?: React.Dispatch<TData[]>;
    error?: boolean;
    serverSearchText?: string;
    setServerSearchText?: (query: string) => void;
    useServerSearch?: boolean;
}

function getSelectionList(selection: RowSelectionState) {
    return Object.entries(selection)
        .filter(([_, v]) => v)
        .map(([k]) => k);
}

function getDragColumn<TData, TValue>(props: DataTableProps<TData, TValue>): Array<ColumnDef<TData>> {
    if (!props.enableRowDragging) {
        return [];
    }
    return [{
        id: 'drag',
        enableSorting: false,
        enableHiding: false,
        maxSize: 10,
        header: () => null,
        cell: () => (
            <div className="table-drag tw-cursor-grab tw-flex tw-justify-center">
                <DragHandleDots2Icon className="tw-size-5" />
            </div>
        )
    }];
}

function getSelectColumn<TData, TValue>(
    props: DataTableProps<TData, TValue>,
    selectLabel: string
): Array<ColumnDef<TData>> {
    if (!props.enableRowSelection) {
        return [];
    }
    const checkboxId = `${props.id ?? ''}-select-all`;
    return [{
        id: 'select',
        enableSorting: false,
        enableHiding: false,
        maxSize: !props.enableRowSelectionCheckboxLabel ? 10 : 60,
        header: ({ table }) => (typeof props.maxSelectionCount !== 'number' &&
            <div className="tw-mx-[-4px] tw-flex tw-items-center table-select">
                <Checkbox
                    id={checkboxId}
                    className="tw-ml-1"
                    checked={
                        table.getIsAllPageRowsSelected() ||
                        (table.getIsSomePageRowsSelected() && 'indeterminate')
                    }
                    onCheckedChange={
                        (value) => table.toggleAllPageRowsSelected(!!value)
                    }
                />
                {props.enableRowSelectionCheckboxLabel &&
                    <Label className="tw-relative tw-bottom-[1px] tw-ml-2" htmlFor={checkboxId}>
                        {selectLabel}
                    </Label>
                }
            </div>
        ),
        cell: ({ row }) => <div className="tw-px-2">
            <Checkbox
                className="tw-translate-y-[1.5px]"
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
            />
        </div>
    }];
}

function getNumberingColumn<TData, TValue>(props: DataTableProps<TData, TValue>): Array<ColumnDef<TData>> {
    if (!props.enableRowNumbering) {
        return [];
    }
    return [{
        id: 'number',
        enableSorting: false,
        enableHiding: false,
        maxSize: 10,
        header: () => null,
        cell: ({ row }) => <div className="tw-font-semibold">{row.index + 1}.</div>
    }];
}

function getColumnId<TData, TValue>(col: ColumnDef<TData, TValue>): string {
    return col.id ?? (col as any).accessorKey?.toString?.() ?? col.header;
}

export function DataTable<TData, TValue>(props: DataTableProps<TData, TValue>) {
    const { t, ct } = useTranslation('data-table');
    const enablePagination = props.enablePagination ?? true;
    const enableGlobalFilter = props.enableGlobalFilter ?? true;
    const enableColumnFilters = props.enableColumnFilters ?? true;
    const enableHiding = props.enableHiding ?? true;
    const [sorting, setSorting] = useControllableState<SortingState>(
        props.initialState?.sorting ?? [],
        props.state?.sorting,
        props.onSortingChange
    );
    const [pagination, setPagination] = useControllableState<PaginationState>(
        props.initialState?.pagination ?? {
            pageIndex: 0,
            pageSize: 10
        },
        props.state?.pagination,
        props.onPaginationChange
    );
    const [rowSelection, setRowSelection] = useControllableState<RowSelectionState>(
        props.initialState?.rowSelection ?? {},
        props.state?.rowSelection,
        (value) => {
            let newValue = newStateFromAction(rowSelection, value);
            if (typeof props.maxSelectionCount === 'number') {
                const diff = _.difference(
                    getSelectionList(newValue),
                    getSelectionList(rowSelection)
                );
                newValue = Object.entries(newValue)
                    .sort(([a], [b]) => Number(diff.includes(b)) - Number(diff.includes(a)))
                    .slice(0, props.maxSelectionCount)
                    .reduce((obj, [k, v]) => ({ ...obj, [k]: v }), {});
            }
            props.onRowSelectionChange?.(newValue);
        }
    );
    const [columnFilters, setColumnFilters] = useControllableState<ColumnFiltersState>(
        props.initialState?.columnFilters ?? [],
        props.state?.columnFilters,
        props.onColumnFiltersChange
    );
    const [columnVisibility, setColumnVisibility] = useControllableState<VisibilityState>(
        props.initialState?.columnVisibility ?? {},
        props.state?.columnVisibility,
        props.onColumnVisibilityChange
    );
    const [globalFilter, setGlobalFilter] = useControllableState(
        props.initialState?.globalFilter ?? '',
        props.state?.globalFilter,
        props.onGlobalFilterChange
    );
    const [showColumnFilters, setShowColumnFilters] = useState(false);
    const data = useMemo(() => props.loading && !props.data.length
        ? [
            ...Array(
                Math.min(pagination.pageSize, props.numSkeletonRows ?? 20)
            ).fill(null)
        ].map(() =>
            Object.assign(
                {},
                ...(props.columns).map(
                    (col) => ({
                        [getColumnId(col)]: null
                    })
                )
            )
        )
        : props.data,
    [props.data, props.loading]
    );
    const table = useReactTable({
        data,
        columns: [
            ...getDragColumn(props),
            ...getSelectColumn(props, ct('select.all')),
            ...getNumberingColumn(props),
            ...props.columns
        ],
        manualPagination: props.manualPagination,
        getCoreRowModel: getCoreRowModel(),
        ...((enablePagination ?? true) &&
            { getPaginationRowModel: getPaginationRowModel() }
        ),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        onRowSelectionChange: setRowSelection,
        onColumnFiltersChange: setColumnFilters,
        onColumnVisibilityChange: setColumnVisibility,
        onGlobalFilterChange: setGlobalFilter,
        getRowId: props.getRowId,
        state: {
            sorting,
            pagination,
            rowSelection,
            columnFilters,
            columnVisibility,
            globalFilter
        }
    });
    const selectedRowCount = table.getFilteredSelectedRowModel().flatRows.length;
    const totalRowCount = props.rowCount ?? table.getPrePaginationRowModel().flatRows.length;
    const pageCount = Math.ceil(totalRowCount / pagination.pageSize);
    const pageRange = 2;
    const pageList = [...Array(2 * pageRange + 1)]
        .map((_, i) => pagination.pageIndex + i - pageRange)
        .filter((i) => i >= 0 && i < pageCount);
    const columnsCanHide = table.getAllColumns()
        .filter((column) => column.getCanHide());
    const rows = table.getRowModel().rows?.length
        ? (
            table.getRowModel().rows.map((row) => (
                <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                >
                    {row.getVisibleCells().map((cell) => {
                        const size = cell.column.getSize();
                        const isSelectColumn = cell.column.id === 'select';
                        const skeletonWidth = isSelectColumn
                            ? 20
                            : Math.round(
                                Math.random() * (size - size / 3) + size / 3
                            );
                        return (
                            <TableCell
                                key={cell.id}
                                className={cn(
                                    ['drag', 'select', 'number'].includes(cell.column.id) &&
                                    '!tw-px-0'
                                )}
                                style={{
                                    minWidth: cell.column.columnDef.minSize,
                                    maxWidth: cell.column.columnDef.maxSize
                                }}
                            >
                                {props.loading
                                    ? <Skeleton
                                        className="tw-h-[18px] tw-m-2"
                                        style={{ width: skeletonWidth }}/>
                                    : flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )
                                }
                            </TableCell>
                        );
                    })}
                </TableRow>
            ))
        )
        : (
            <TableRow>
                <TableCell
                    colSpan={table.getVisibleLeafColumns().length}
                    className="tw-h-24 tw-text-center"
                >
                    {t('no-results')}
                </TableCell>
            </TableRow>
        );
    return (
        <div className={cn('tw-flex tw-flex-col tw-gap-4', props.className)}>
            <div className="tw-flex tw-flex-wrap tw-gap-3">
                {props.slots?.header}
                <div className="tw-ml-auto tw-flex tw-flex-wrap tw-gap-3 empty:tw-hidden">
                    {(enableGlobalFilter || props?.useServerSearch) && (
                        <Input
                            className="!tw-w-sm"
                            placeholder={t('filter')}
                            prependIcon={<MagnifyingGlassIcon />}
                            prependBorder={false}
                            value={props?.useServerSearch ? props?.serverSearchText : globalFilter}
                            onChange={(e) => {
                                if (props?.useServerSearch && props?.setServerSearchText) {
                                    props.setServerSearchText(e.target.value);
                                } else {
                                    setGlobalFilter(e.target.value);
                                }
                            }}
                        />
                    )}
                    {enableHiding && columnsCanHide.length > 0 && <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button type="button" variant="outline" className="tw-ml-auto">
                                {t('columns')}
                                <CaretSortIcon className="tw-ml-6 tw-opacity-50"/>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                            {columnsCanHide.map((column) => (
                                <DropdownMenuCheckboxItem
                                    key={column.id}
                                    className="tw-capitalize"
                                    checked={column.getIsVisible()}
                                    onCheckedChange={(value) =>
                                        column.toggleVisibility(value)
                                    }
                                >
                                    {flexRender(
                                        column.columnDef.header,
                                        {
                                            table,
                                            column
                                        } as any
                                    )}
                                </DropdownMenuCheckboxItem>
                            ))}
                        </DropdownMenuContent>
                    </DropdownMenu>}
                    {enableColumnFilters &&
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button
                                    type="button" variant="outline"
                                    onClick={() => setShowColumnFilters(v => !v)}
                                >
                                    <FontAwesomeIcon
                                        className="tw-mr-2"
                                        icon={showColumnFilters ? faEye : faEyeSlash}
                                    />
                                    {t('filters.title')}
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                {t('filters.tooltip')}
                            </TooltipContent>
                        </Tooltip>
                    }
                </div>
            </div>
            <div
                className={cn(
                    'tw-border tw-border-input tw-rounded-sm',
                    'focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring',
                    'aria-invalid:tw-border-destructive aria-invalid:focus-visible:tw-ring-destructive'
                )}
                aria-invalid={props.error ? 'true' : 'false'}
                tabIndex={0}
            >
                <Table className="tw-border-b">
                    <TableHeader>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => {
                                    const columnDef =
                                        header.column.columnDef as DataTableColumn<TData, TValue>;
                                    const id = header.column.id;
                                    const filter = columnDef.filter ?? {
                                        type: CrudInputType.TEXT,
                                        name: header.column.id
                                    };
                                    const filterValue = header.column.getFilterValue();
                                    const isFiltered = filterValue != null && filterValue !== '';
                                    const filterIcon = isFiltered && (
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <Button
                                                    type="button" variant="ghost"
                                                    className="!tw-size-4 !tw-p-0"
                                                    onClick={() => header.column.setFilterValue(undefined)}
                                                >
                                                    <FontAwesomeIcon icon={faFilter} />
                                                </Button>
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                {t('filters.clear')}
                                            </TooltipContent>
                                        </Tooltip>
                                    );
                                    return (<TableHead
                                        key={header.id}
                                        style={{
                                            transition: 'max-height 0.3 linear',
                                            width: header.column.columnDef.size,
                                            minWidth: header.column.columnDef.minSize,
                                            maxWidth: header.column.columnDef.maxSize
                                        }}
                                    >
                                        <div className="tw-flex tw-flex-col">
                                            {header.column.getCanSort() &&
                                                <div className="tw-flex tw-items-center tw-gap-2">
                                                    <Button
                                                        type="button"
                                                        variant="ghost"
                                                        className={cn(
                                                            'tw-flex tw-items-center tw-gap-2',
                                                            'tw-mx-[-8px] !tw-px-2'
                                                        )}
                                                        onClick={() => header.column.toggleSorting()}
                                                    >
                                                        {header.isPlaceholder
                                                            ? null
                                                            : flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                        {!header.column.getIsSorted() &&
                                                            <CaretSortIcon/>
                                                        }
                                                        {header.column.getIsSorted() === 'asc' &&
                                                            <CaretUpIcon/>
                                                        }
                                                        {header.column.getIsSorted() === 'desc' &&
                                                            <CaretDownIcon/>
                                                        }
                                                    </Button>
                                                    {filterIcon}
                                                </div>
                                            }
                                            {!header.column.getCanSort() && !header.isPlaceholder &&
                                                flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )
                                            }
                                            {!header.column.getCanSort() && !header.isPlaceholder && filterIcon}
                                            {header.column.getCanFilter() && showColumnFilters &&
                                                <div className={cn(
                                                    'tw-mb-2 tw-min-h-[36px] tw-flex-1 tw-flex tw-items-center'
                                                )}>
                                                    <InputField
                                                        {...filter}
                                                        id={id}
                                                        field={{
                                                            name: id,
                                                            ref: () => {},
                                                            onBlur: () => {},
                                                            value: header.column.getFilterValue(),
                                                            onChange: (event) => {
                                                                const value = event.target
                                                                    ? event.target.value
                                                                    : event;
                                                                header.column.setFilterValue(value);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            }
                                            {!header.column.getCanFilter() && showColumnFilters &&
                                                <div className="tw-h-[36px] tw-mb-2" />
                                            }
                                        </div>
                                    </TableHead>);
                                })}
                            </TableRow>
                        ))}
                    </TableHeader>
                    {props.enableRowDragging
                        ? <ReactSortable
                            className="[&_tr:last-child]:tw-border-0"
                            handle=".table-drag"
                            tag="tbody"
                            animation={150}
                            list={data}
                            setList={(value) => props.onChangeData?.(value as TData[])}
                        >
                            {rows}
                        </ReactSortable>
                        : <TableBody>
                            {rows}
                        </TableBody>
                    }
                </Table>
                {enablePagination && <Pagination className="tw-p-2 tw-flex-wrap tw-gap-2">
                    {selectedRowCount > 0 && <div
                        className="tw-flex-1 tw-text-sm tw-text-muted-foreground tw-flex tw-items-center">
                        {selectedRowCount} {ct('of')} {totalRowCount} {t('rows-selected')}
                    </div>}
                    <PaginationContent className="tw-ml-auto tw-flex-wrap tw-justify-end">
                        <PaginationItem className="tw-flex tw-items-center tw-gap-2">
                            {t('rows-per-page')}
                            <Select
                                disabled={!!props.loading || props.refetching}
                                value={String(pagination.pageSize)}
                                onValueChange={(value) => table.setPageSize(Number(value) ?? 10)}
                            >
                                <SelectTrigger className="!tw-w-[70px]">
                                    <SelectValue/>
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectItem value="10">10</SelectItem>
                                        <SelectItem value="20">20</SelectItem>
                                        <SelectItem value="25">25</SelectItem>
                                        <SelectItem value="50">50</SelectItem>
                                        <SelectItem value="100">100</SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </PaginationItem>
                        <PaginationItem>
                            <Button
                                type="button" variant="ghost" size="icon"
                                disabled={!!props.loading || props.refetching}
                                onClick={() => table.setPageIndex(0)}
                            >
                                <DoubleArrowLeftIcon/>
                            </Button>
                        </PaginationItem>
                        {pageList.map((index) => (
                            <PaginationItem key={index}>
                                <Button
                                    type="button" size="icon"
                                    variant={index === pagination.pageIndex ? 'outline' : 'ghost'}
                                    disabled={!!props.loading || props.refetching}
                                    onClick={() => table.setPageIndex(index)}
                                >
                                    {index + 1}
                                </Button>
                            </PaginationItem>
                        ))}
                        <PaginationItem>
                            <Button
                                type="button" variant="ghost" size="icon"
                                disabled={!!props.loading || props.refetching}
                                onClick={() => table.setPageIndex(pageCount - 1)}
                            >
                                <DoubleArrowRightIcon/>
                            </Button>
                        </PaginationItem>
                    </PaginationContent>
                </Pagination>}
            </div>
        </div>
    );
}
