import { useUserStore } from '@/store/user';
import { type LoaderFunction } from '@remix-run/router/utils';
import { redirectWithParams } from '@/composables/utils';

export enum Feature {
    NEODOC = 'neodoc',
    NEOFORM = 'neoform',
    PSJ = 'psj',
    CALENDAR = 'calendar'
}

export function useFeatures() {
    const user = useUserStore(state => state.user);
    return {
        isFeatureEnabled: (feature?: Feature) => {
            return !feature || (user?.features ?? []).includes(feature);
        }
    };
}

function getHomePagePath(features: Feature[]) {
    if (features.includes(Feature.PSJ)) {
        return '/dashboard';
    }
    if (features.includes(Feature.NEOFORM)) {
        return '/forms';
    }
    return '/unauthorized';
}

export function RequiredFeatureLoader(...required: Feature[]): LoaderFunction {
    return ({ request }) => {
        const { user, token } = useUserStore.getState();
        const features = user?.features ?? [];
        if (token && required.some(f => !features.includes(f))) {
            return redirectWithParams(getHomePagePath(features), new URL(request.url));
        }
        return null;
    };
}
