import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';

const alertVariants = cva(
    'tw-relative tw-w-full tw-rounded-lg tw-border tw-px-4 tw-py-3 tw-text-sm ' +
    '[&>svg+div]:tw-translate-y-[-3px] [&>svg]:tw-absolute [&>svg]:tw-left-4 ' +
    '[&>svg]:tw-top-4 [&>svg]:tw-text-foreground [&>svg~*]:tw-pl-7',
    {
        variants: {
            variant: {
                default: 'tw-bg-background tw-text-foreground',
                destructive:
                    'tw-border-destructive/50 tw-text-destructive ' +
                    'dark:tw-border-destructive [&>svg]:tw-text-destructive',
                warning: 'tw-border-warning/50 tw-text-warning ' +
                    'dark:tw-border-warning [&>svg]:tw-text-warning'
            }
        },
        defaultVariants: {
            variant: 'default'
        }
    }
);

const Alert = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({
    className,
    variant,
    ...props
}, ref) => (
    <div
        ref={ref}
        role="alert"
        className={cn(alertVariants({ variant }), className)}
        {...props}
    />
));
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef<
    HTMLParagraphElement,
    React.HTMLAttributes<HTMLHeadingElement>
>(({
    className,
    ...props
}, ref) => (
    <h5
        ref={ref}
        className={cn('tw-mb-1 tw-font-medium tw-leading-none tw-tracking-tight', className)}
        {...props}
    />
));
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<
    HTMLParagraphElement,
    React.HTMLAttributes<HTMLParagraphElement>
>(({
    className,
    ...props
}, ref) => (
    <div
        ref={ref}
        className={cn('tw-text-sm [&_p]:tw-leading-relaxed', className)}
        {...props}
    />
));
AlertDescription.displayName = 'AlertDescription';

export { Alert, AlertTitle, AlertDescription };
