import React from 'react';
import { type FactoryOpts } from 'imask';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import { useNeoForm } from '@/composables/neoform';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import { useIMask } from 'react-imask';
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { getFieldError } from '@/composables/validation';

interface Props {
    placeholder?: TranslationObject;
    textarea?: boolean;
    mask?: FactoryOpts['mask'];
    uppercase?: boolean;
    definitions?: Record<string, RegExp | string>;
}

export function InputText(props: NeoFormComponentProps & Props) {
    const { to } = useTranslation();
    const {
        form,
        id,
        getValidationRules
    } = useNeoForm();
    const validate = getValidationRules(props);
    const maskValue = typeof props.mask === 'string' && props.mask.startsWith('/')
        ? new RegExp(props.mask.substring(1, props.mask.length - 1))
        : props.mask;
    const definitions: Record<string, RegExp> = Object.entries(props.definitions ?? {})
        .reduce((obj, [k, v]) => ({
            ...obj,
            [k]: typeof v === 'string' ? new RegExp(v) : v
        }), {});
    return (
        <FormField
            name={id}
            defaultValue={to(props.default)}
            render={({ field, formState }) => {
                const error = !!getFieldError(id, formState);
                const mask = props.mask
                    ? useIMask(
                        {
                            mask: maskValue,
                            definitions,
                            prepareChar: props.uppercase
                                ? (str: string) => str.toUpperCase()
                                : undefined
                        } as any,
                        {
                            onAccept: value => {
                                field.onChange(value);
                                form?.set(id, value);
                            }
                        }
                    )
                    : undefined;
                if (props.mask && field.value !== mask?.value && !!field.value) {
                    mask?.setValue(field.value);
                }
                return (
                    <FormItem>
                        <FormControl>
                            {React.createElement(
                                (props.textarea ? Textarea : Input) as typeof Input,
                                {
                                    id,
                                    error,
                                    disabled: props.readonly,
                                    placeholder: to(props.placeholder) || undefined,
                                    ...field,
                                    ref: (ref) => {
                                        if (mask) {
                                            mask.ref.current = ref;
                                        }
                                        field.ref(ref);
                                        form?.ref(id, ref);
                                    },
                                    value: field.value ?? '',
                                    onChange: (e) => {
                                        const input = e.target;
                                        if (mask) {
                                            mask.setValue(input.value);
                                        } else {
                                            if (props.uppercase) {
                                                const start = input.selectionStart;
                                                const end = input.selectionEnd;
                                                input.value = input.value.toUpperCase();
                                                input.setSelectionRange(start, end);
                                            }
                                            field.onChange(input.value);
                                            form?.set(id, input.value);
                                        }
                                    }
                                }
                            )}
                        </FormControl>
                        <FormMessage/>
                    </FormItem>
                );
            }}
            rules={{ validate }}
        />
    );
}
