import React, { type SetStateAction } from 'react';
import { useTranslation } from '@/composables/translation';
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { Form } from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { useControllableState } from '@/composables/controllable';

interface Props {
    open?: boolean;
    title?: string;
    message: string;
    cancelText?: string;
    confirmText?: string;
    children?: React.ReactNode;
    onOpenChange?: React.Dispatch<SetStateAction<boolean>>;
    onConfirm?: () => (void | Promise<void>);
}

export function ConfirmDialog(props: Props) {
    const { ct } = useTranslation();
    const form = useForm();
    const [open, setOpen] = useControllableState(false, props.open, props.onOpenChange);
    async function handleConfirm() {
        try {
            await props.onConfirm?.();
            setOpen(false);
        } catch (err) {}
    }
    return (
        <Dialog open={open} onOpenChange={setOpen}>
            {props.children}
            <DialogContent className="!tw-max-w-md">
                <DialogHeader>
                    {props.title && <DialogTitle>{props.title}</DialogTitle>}
                </DialogHeader>
                <div
                    className="tw-prose"
                    dangerouslySetInnerHTML={{ __html: props.message }}
                >
                </div>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button variant="secondary">
                            {props.cancelText ?? ct('cancel')}
                        </Button>
                    </DialogClose>
                    <Form {...form}>
                        <form onSubmit={(event) => {
                            event.stopPropagation();
                            form.handleSubmit(handleConfirm)(event);
                        }}>
                            <ButtonSubmit variant="destructive">
                                {props.confirmText ?? ct('confirm')}
                            </ButtonSubmit>
                        </form>
                    </Form>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}
