import React from 'react';
import { Input, type InputProps } from '@/components/ui/input';
import { useIMask } from 'react-imask';
import { type MaskedNumberOptions } from 'imask/masked/number';
import { assignRef } from '@/composables/utils';
import { cn } from '@/lib/utils';

interface Props extends Omit<InputProps, 'value' | 'onChange'> {
    maskOptions?: Omit<MaskedNumberOptions, 'mask'>;
    value?: number;
    onChange?: React.Dispatch<number>;
}

const InputNumber =
    React.forwardRef<HTMLInputElement, Props>(({ maskOptions, value, onChange, ...props }, ref) => {
        const mask = useIMask(
            {
                mask: Number,
                ...maskOptions,
                scale: maskOptions?.scale ?? 0,
                radix: maskOptions?.radix ?? '.'
            },
            {
                onAccept: (_, mask) => {
                    const value = mask.value.trim() === '' ? NaN : mask.typedValue;
                    onChange?.(value);
                }
            }
        );
        let inputValue = value !== mask.typedValue ? value : mask.value;
        if (Number.isNaN(inputValue)) {
            inputValue = '';
        }
        return (
            <Input
                {...props}
                className={cn('tw-font-mono', props.className)}
                ref={(r) => {
                    assignRef(mask.ref, r);
                    assignRef(ref, r);
                }}
                value={inputValue ?? ''}
                onChange={e => mask.setValue(e.target.value)}
            />
        );
    });
InputNumber.displayName = 'InputNumber';

export { InputNumber };
