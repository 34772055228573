/* eslint @typescript-eslint/no-implied-eval: 0 */
/* eslint @typescript-eslint/ban-types: 0 */
/* eslint no-new-func: 0 */

import { useContext } from 'react';
import _ from 'lodash';

import {
    type NeoFormData,
    type NeoFormFunction,
    type NeoFormFunctionCase,
    type NeoFormLayout,
    type NeoFormMeta,
    NeoFormStatus
} from '@/types/neoform';
import { type FormFunction, NeoFormContext } from '@/components/neoform/context/NeoFormContext';
import { NeoFormComponentContext } from '@/components/neoform/context/NeoFormComponentContext';
import { clone } from '@/composables/utils';
import { useFormContext, type Validate } from 'react-hook-form';
import { useValidation } from '@/composables/validation';
import { type FormListEntry } from '@/types/api/forms';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import { useTranslation } from '@/composables/translation';

export function useNeoForm() {
    const hookForm = useFormContext();
    const form = useContext(NeoFormContext);
    const component = useContext(NeoFormComponentContext);
    const validation = useValidation();
    const { to } = useTranslation();

    const id = (component?.path ?? []).join('.');

    function getChildFieldName(...childPath: Array<string | number>) {
        return [...(component?.path ?? []), ...childPath].join('.');
    }

    function hasPermission(name: string, strict = false) {
        return form?.info?.permissions?.some(p =>
            (!strict && p.startsWith(name)) ||
            name.startsWith(`${p}.`) ||
            name === p
        ) ?? true;
    }

    function hasFieldPermission(strict = false) {
        return hasPermission(id, strict);
    }

    function hasChildFieldPermission(childPath: Array<string | number>, strict = false) {
        return hasPermission(getChildFieldName(...childPath), strict);
    }

    function parseConditionalFunction(
        name: string,
        value: NeoFormFunction,
        args: string[]
    ): Partial<FormFunction> {
        if (!form) {
            return {};
        }
        if (form.functions[name]) {
            return form.functions[name];
        }
        const src = getFunctionSource(value);
        try {
            form.functions[name] = {
                dependencies: getFunctionDependencies(src),
                fn: new Function(...args, src)
            };
        } catch (err) {
            console.error(err);
            return {};
        }
        return form.functions[name];
    }

    function getValidationRules(
        props: NeoFormComponentProps
    ): Record<string, Validate<any, any>> {
        const obj = (props.validationRules ?? [])
            .filter(r => r !== 'required')
            .map(r => (validation as any)[r])
            .filter(r => !!r)
            .reduce((obj, r) => ({
                ...obj,
                [r.name]: (value: any) => !value || r(value)
            }), {});
        if (props.required) {
            obj.required = validation.required;
        }
        if (props.onValidate) {
            const {
                fn
            } = parseConditionalFunction(
                `${id}_onvalidate`,
                props.onValidate,
                ['value', 'data']
            );
            if (fn) {
                obj.custom = (value: any, data: any) => {
                    const result = fn(value, data);
                    if (result !== true) {
                        return to(result);
                    }
                    return true;
                };
            }
        }
        return obj;
    }

    return {
        hookForm,
        form,
        component,
        id,
        getChildFieldName,
        hasFieldPermission,
        hasChildFieldPermission,
        parseConditionalFunction,
        getValidationRules
    };
}

export function invokeFunction<T>(fn: Function | undefined, args: any[]): T | undefined {
    try {
        return fn?.apply(undefined, args);
    } catch (err) {
        console.error(err);
        return undefined;
    }
}

function addSemiColon(line: string) {
    const trimmed = line.trim();
    if (!trimmed.endsWith(';')) {
        return `${trimmed};`;
    }
    return trimmed;
}

function getFunctionSource(value: NeoFormFunction) {
    if (typeof value === 'string') {
        return `return ${value}`;
    }

    if (Array.isArray(value) && typeof value[0] === 'string') {
        return (value as string[])
            .map((line, index) => {
                if (index !== value.length - 1) {
                    return addSemiColon(line);
                }
                return `return ${line};`;
            })
            .join('\n');
    }

    return (value as NeoFormFunctionCase[])
        .map(o => {
            if (o.condition) {
                return `if (${o.condition}) { return ${o.value}; }`;
            }
            return `{ return ${o.value}; }`;
        })
        .join(' else ');
}

function getFunctionDependencies(src: string) {
    const regex = /data((?:\s*\??\.\s*[_$a-zA-Z][_$a-zA-Z0-9]*|\s*(?:\?\.)?\s*\[\s*'[^']*'\s*])+)/g;
    const inner = /\s*\??\.\s*[_$a-zA-Z][_$a-zA-Z0-9]*|\s*(?:\?\.)?\s*\[\s*'[^']*'\s*]/g;
    const string = /'([^']*)'/;
    const chains = [...src.matchAll(regex)].map(c => c[1]);
    return Array.from(new Set(chains.map(chain => {
        const parts = [...chain.matchAll(inner)];
        const path = [];
        for (const part of parts) {
            const string_match = part[0]?.match(string);
            if (string_match?.[1]) {
                path.push(string_match[1]);
                continue;
            }
            path.push(part[0].replace(/\??\./, ''));
        }
        return path.join('.');
    })));
}

function matchTree(data: NeoFormData, meta: NeoFormMeta) {
    const stack = [...Object.keys(meta).filter(k => k !== 'meta')];
    while (stack.length > 0) {
        const path = stack.pop() ?? '';
        const currentData = _.get(data, path, undefined);
        if (!currentData) {
            _.unset(meta, path);
            continue;
        }
        let value = _.get(meta, path, {});
        value = typeof value !== 'object' ? {} : value;
        const currentMeta = Object.keys(value)
            .filter(k => k !== 'meta')
            .map(k => `${path}.${k}`);
        stack.push(...currentMeta);
    }
}

export function preProcessNeoformMeta(layout: NeoFormLayout, data: NeoFormData, meta: NeoFormMeta) {
    const finalMeta = clone(meta);
    matchTree(data, finalMeta);
    for (const section of layout.body) {
        if (section.header.private) {
            continue;
        }
        _.set(
            finalMeta,
            `${section.header.sectionPrefix}.meta`,
            {
                title: section.header.legend
            }
        );
        const components = section.rows
            .reduce((arr, r) => [...arr, ...r], []);
        for (const component of components) {
            const fullName = `${section.header.sectionPrefix}.${component.name}`;
            _.set(
                finalMeta,
                `${fullName}.meta`,
                {
                    title: component.title,
                    description: component.description,
                    info: component.info
                }
            );
        }
    }
    return finalMeta;
}

function formatDataTree(node: any, prefix: string[] = []): any {
    const keys = (node && typeof node === 'object' ? Object.keys(node) : [])
        .filter(c =>
            c !== 'meta' &&
            node[c].meta?.showInOutline !== false
        );
    return keys.map(c => {
        const path = [...prefix, c];
        const id = path.join('.');
        const name = node[c].meta?.title;
        return {
            id,
            name: name ?? c,
            children: formatDataTree(node[c], path)
        };
    });
}

export function computeNeoformOutline(meta: NeoFormMeta) {
    const tree = clone(meta);
    delete tree.document;
    return formatDataTree(tree);
}

export function getFormUrl(name: string, task_id?: string, token?: string) {
    if (task_id) {
        return `${OLD_FORMS.has(name) ? '/v1' : ''}/form/${name}/${task_id}`;
    } else if (token) {
        return `${OLD_FORMS.has(name) ? '/v1' : ''}/public/${token}`;
    }
    return '';
}

export function getFormStatusFromList(
    status: FormListEntry['status']
): [NeoFormStatus, number | undefined] {
    if (status.lawyer === NeoFormStatus.CLOSED) {
        return [NeoFormStatus.CLOSED, undefined];
    }
    const statusList = Object.values(status);
    if (statusList.every((s) => s === NeoFormStatus.COMPLETED)) {
        return [NeoFormStatus.COMPLETED, undefined];
    }
    const progress = Math.floor(
        100 * statusList.filter(s => s === NeoFormStatus.COMPLETED).length /
        statusList.length
    );
    return [NeoFormStatus.OPEN, progress];
}

export const OLD_FORMS = new Set([
    'mutual-divorce',
    'mutualDivorce',
    'contestedDivorce'
]);
